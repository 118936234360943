import React, { useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  IconButton,
} from "@material-ui/core";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid #C5C5C5`,
      borderRadius: 4,
      backgroundColor: "#F9F9F9",
      padding: theme.spacing(3),
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    title: {
      fontWeight: "bold",
      color: "#37474F",
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1, 0),
      justifyContent: "flex-start",
      textTransform: "none",
      backgroundColor: "#FFFFFF",
      color: "#37474F",
      borderColor: "#B0BEC5",
      borderWidth: 1,
      borderStyle: "solid",
      "&:hover": {
        backgroundColor: "#ECEFF1",
      },
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    linkText: {
      fontSize: 16,
      fontWeight: 500,
    },
    fallbackContainer: {
      textAlign: "center",
      padding: theme.spacing(4),
    },
    fallbackMessage: {
      fontSize: 18,
      color: "#37474F",
    },
    linkCard: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      border: `1px solid #E0E0E0`,
      backgroundColor: "#FFFFFF",
      transition: "box-shadow 0.2s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
      },
    },
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 48,
      height: 48,
      borderRadius: "50%",
      backgroundColor: "#ECEFF1",
      marginRight: theme.spacing(2),
    },
    linkTextContainer: {
      flexGrow: 1,
    },
    linkLabel: {
      fontSize: 16,
      fontWeight: 500,
      color: "#37474F",
    },
    iconButton: {
      marginLeft: theme.spacing(1),
    },
    infoText: {
      color: "#37474F",
      fontSize: 16,
      marginBottom: theme.spacing(2),
      lineHeight: 1.5,
    },
    iframeContainer: {
      width: "100%",
      height: "800px",
      border: "none",
      marginTop: theme.spacing(4),
    },
  })
);

type LinkItem = {
  label: string;
  url: string;
};

//Browser Validation using userAgent

// const isWebex = () => {
//   // Check for Webex context
//   const userAgent = navigator.userAgent || "";
//   return (
//     userAgent.includes("Webex") ||
//     userAgent.includes("webex") ||
//     userAgent.includes("cisco") ||
//     userAgent.includes("Cisco")
//   );
// };

const CiscoMeetingSpaces = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);

  const links: LinkItem[] = [
    {
      label: "Adelaide - 10 Franklin St ",
      url: "https://workspaces.dnaspaces.io/?token=f95ae455-31ec-4dc3-9bf3-882be087e3ca&src=dnas-signage&tmp=1726034600125#/",
    },
    {
      label: "Adelaide - Export Park ",
      url: "https://workspaces.dnaspaces.io/?token=53c172d8-660c-4867-bece-0a41807c6f5b&src=dnas-signage&tmp=1731559777018#/",
    },
    {
      label: "Kuala Lumpur - Southpoint Tower ",
      url: "https://workspaces.dnaspaces.io/?token=cc1c9713-fd84-4354-a665-30912cdf95c4&src=dnas-signage&tmp=1726103031371#/",
    },
    {
      label: "Melbourne - 171 Collins St ",
      url: "https://workspaces.dnaspaces.io/?token=048d2c0c-84e7-4245-b915-09a42694e3a7&src=dnas-signage&tmp=1723421181851#/",
    },
    {
      label: "Brisbane, 480 Queen Street ",
      url: "https://workspaces.dnaspaces.io/?token=196d6838-657e-4f4f-aab9-b0f3ddea24e3&src=dnas-signage&tmp=1736397351199#/",
    },
  ];

  const handleLinkClick = (url: string) => {
    setSelectedUrl(url);
  };

  return (
    <Box className={classes.container}>
      {selectedUrl ? (
        <Box>
          {/* <Typography variant="h6">Building Kiosk</Typography> */}
          <iframe src={selectedUrl} className={classes.iframeContainer} title="Kiosk" />
          <Box mt={2}>
            <IconButton onClick={() => setSelectedUrl(null)}>
              <LaunchIcon style={{ color: "#455A64" }} />
            </IconButton>
            <Typography>Click here to go back to the list of buildings</Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Typography className={classes.infoText}>
            {" "}
            The Cisco Spaces Kiosks allow you to locate meeting spaces on the floor and hold an
            available meeting space for a short period of time, allowing you to walk in and occupy
            the space.{" "}
          </Typography>{" "}
          <Typography className={classes.infoText} style={{ fontStyle: "italic" }}>
            {" "}
            Cisco Spaces is in the progress of being rolled out to all BHP corporate office
            locations. Where your location is not available below, please await communications from
            your local Property and Workplace team regarding the Cisco Spaces rollout in your
            location.{" "}
          </Typography>{" "}
          <Typography className={classes.infoText}>
            {" "}
            Please click one of the links below to access the buildings kiosk page.{" "}
          </Typography>
          <Grid container spacing={2}>
            {links.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  className={classes.linkCard}
                  onClick={() => handleLinkClick(item.url)}
                  elevation={0}
                >
                  <div className={classes.iconWrapper}>
                    <LocationCityIcon style={{ color: "#455A64" }} />
                  </div>
                  <div className={classes.linkTextContainer}>
                    <Typography className={classes.linkLabel}>{item.label}</Typography>
                  </div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLinkClick(item.url);
                    }}
                  >
                    <LaunchIcon style={{ color: "#455A64" }} />
                  </IconButton>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CiscoMeetingSpaces;
