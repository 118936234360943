import Topbar from "./Topbar";
import { observer } from "mobx-react-lite";
import { useBuildingStore, useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import OfficeMainPage from "components/office/OfficeMainPage";
import OfficeTabMainPage from "components/office/OfficeTabMainPage";
import OfficeDetailPage from "components/office/OfficeDetailPage";
import VisitorCheckin from "components/office/VisitorCheckin";
import CiscoMeetingSpaces from "components/office/CiscoMeetingSpaces";
import { BuildingWithCapacity } from "interfaces/Building";
import { Box, createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { bhpColor } from "styles/globals";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { exit } from "process";

interface OfficeProps {
  officeId?: number;
  match?: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainPage: {
      color: bhpColor.blueGrey1,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: bhpColor.backgroundGrey,
      },
      [theme.breakpoints.down("xs")]: {
        backgroundColor: bhpColor.white,
        "&.main": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
  })
);

const Office = observer((props: OfficeProps) => {
  const officeId = props.match.params.officeId || null;
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const teamStore = useTeamStore();
  const classes = innerStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const myOffice =
    !officeId && userStore.me?.profile.default_office
      ? buildingStore.getBuilding(userStore.me?.profile.default_office)
      : null;

  const theBuildingId = officeId ? parseInt(officeId) : myOffice?.id || null;
  buildingStore.setCurrentOffice(theBuildingId);

  const [theBuilding, setTheBuilding] = useState<BuildingWithCapacity | null>(
    theBuildingId ? buildingStore.getBuilding(theBuildingId) : null
  );

  const oddDay = (building: BuildingWithCapacity | null) => {
    const buildingDay =
      building && building.building_timezone
        ? moment(new Date()).tz(building?.building_timezone).format("ddd")
        : "";
    const currentDay = moment(new Date()).format("ddd");
    return buildingDay !== currentDay && buildingDay !== "" ? buildingDay : "";
  };

  const history = useHistory();
  let title = "";
  let subtitle = "";
  let breadcrumb = "";
  if (history.location.pathname.indexOf("visitor-checkin") > -1) {
    title = "Visitor Check-In";
    subtitle = "BHP My Workday Platform";
  } else if (history.location.pathname.indexOf("visitor-registration") > -1) {
    title = "BHP Locations";
    subtitle = "View and access BHP locations";
  } else if (history.location.pathname.indexOf("office-access") > -1) {
    title = "BHP Locations";
    subtitle = "Request access to BHP location";
  } else if (history.location.pathname.indexOf("service") > -1) {
    title = "BHP Locations";
    subtitle = "Property Assist";
  } else if (history.location.pathname.indexOf("cisco-meetings") > -1) {
    title = "Welcome to the Cisco Spaces Meeting Room Kiosk Landing page";
    subtitle = "";
  } else {
    title = !officeId
      ? t("BHP Locations")
      : buildingStore.currentOffice
      ? `${buildingStore.currentOffice.city}, ${buildingStore.currentOffice.building_name}`
      : "";
    subtitle = !officeId
      ? t("View and access BHP locations")
      : buildingStore.currentOffice
      ? `${t("Current Time")} ${oddDay(buildingStore.currentOffice)} ${
          buildingStore.currentOffice.building_timezone
            ? moment(new Date()).tz(buildingStore.currentOffice.building_timezone).format("h:mma")
            : ""
        } (${
          buildingStore.currentOffice.building_timezone
            ? moment().tz(buildingStore.currentOffice.building_timezone).format("z")
            : ""
        })`
      : "";
    let breadcrumb = `${t("Office")} ${
      officeId && buildingStore.currentOffice
        ? `- ${buildingStore.currentOffice.city} ${buildingStore.currentOffice.building_name}`
        : ""
    }`;
  }

  useEffect(() => {
    if (
      !officeId &&
      !buildingStore.isLoadingCapacity &&
      buildingStore.buildingsWithCapacity.length === 0
    )
      buildingStore.loadBuildingsWithCapacity(moment(new Date()).format("YYYY-MM-DD"));
    if (
      !officeId &&
      !buildingStore.isLoadingCapacity &&
      buildingStore.buildingsWithCapacity.length > 0 &&
      buildingStore.buildingsWithCapacity.length < 50
    )
      buildingStore.loadBuildingsWithCapacity(moment(new Date()).format("YYYY-MM-DD"), true);
    //if (!teamStore.isLoading && teamStore.teams.length === 0) teamStore.loadTeams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTheBuilding(buildingStore.currentBuilding);
  }, [buildingStore.setCurrentOffice]);

  let render_component;
  if (
    officeId &&
    (history.location.pathname.indexOf("visitor-registration") > -1 ||
      history.location.pathname.indexOf("office-access") > -1 ||
      history.location.pathname.indexOf("service") > -1) == false
  ) {
    render_component = <OfficeDetailPage t={t} building={buildingStore.currentOffice} />;
  } else if (history.location.pathname.indexOf("visitor-checkin") > -1) {
    render_component = <VisitorCheckin />;
  } else if (history.location.pathname.indexOf("cisco-meetings") > -1) {
    render_component = <CiscoMeetingSpaces />;
  } else {
    render_component = <OfficeTabMainPage t={t} myOffice={myOffice} />;
  }

  return (
    <Box>
      <Topbar title={title} subtitle={subtitle} breadcrumb={breadcrumb} />
      <Box
        py={{ xs: 2, md: 4 }}
        px={{ xs: 2, md: 4 }}
        className={`${classes.mainPage} ${officeId ? "" : "main"}`}
      >
        {render_component}
      </Box>
    </Box>
  );
});

export default Office;
