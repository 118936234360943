import { observer } from "mobx-react-lite";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import { Schedule, SettingsScheduleType } from "interfaces/Schedule";
import {
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getTimes } from "utils/time";
import { useEffect, useState } from "react";
import config from "config";
import { EditHoursLocationType } from "interfaces/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    textFieldLabel: {
      paddingLeft: 3,
      paddingBottom: 5,
      paddingTop: 10,
      fontWeight: "bold",
      color: "#466371",
    },
    timeDropDown: {
      width: "90%",
      textAlign: "center",
      height: 40,
    },
  })
);

interface EditHoursLocationDialogProps {
  t: any;
  schedule?: Schedule | null;
  handleHoursLocationSave?: any;
  handleResponseClick?: any;
  isOpen: boolean;
  day?: string | null;
  workType: number | null;
  editHoursLocationType: EditHoursLocationType;
  settingsDefaultWeek?: SettingsScheduleType | null;
}

const EditHoursLocationDialog = observer((props: EditHoursLocationDialogProps) => {
  const {
    t,
    schedule,
    handleResponseClick,
    isOpen,
    day,
    workType,
    handleHoursLocationSave,
    editHoursLocationType,
    settingsDefaultWeek,
  } = props;
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const classes = useStyles();
  const [selectedLocation, setSelectedLocation] = useState<number | null>(
    schedule?.office?.id ||
      settingsDefaultWeek?.office ||
      userStore.me?.profile.default_office ||
      null
  );
  const [selectedSpace, setSelectedSpace] = useState<number | 0>(schedule?.space?.id || 0);
  const [startTime, setStartTime] = useState<string>(config.workday.defaultStartWorkingHour);
  const [finishTime, setFinishTime] = useState<string>(config.workday.defaultFinishWorkingHour);

  const [startTimeDesk, setStartTimeDesk] = useState<string>(
    config.workday.defaultStartWorkingHour
  );
  const [finishTimeDesk, setFinishTimeDesk] = useState<string>(
    config.workday.defaultFinishWorkingHour
  );

  const handleSave = () => {
    switch (editHoursLocationType) {
      case EditHoursLocationType.Planner:
        handleHoursLocationSave(
          schedule,
          startTime,
          finishTime,
          startTimeDesk,
          finishTimeDesk,
          selectedLocation
        );
        break;
      case EditHoursLocationType.Settings: {
        handleHoursLocationSave(
          day,
          startTime,
          finishTime,
          startTimeDesk,
          finishTimeDesk,
          selectedLocation
        );
        break;
      }
      default:
        break;
    }
  };

  const handleCancel = () => {
    handleResponseClick();
  };

  useEffect(() => {
    if (editHoursLocationType === EditHoursLocationType.Settings) {
      settingsDefaultWeek && settingsDefaultWeek?.start_time
        ? setStartTime(settingsDefaultWeek.start_time)
        : setStartTime(config.workday.defaultStartWorkingHour);
      settingsDefaultWeek && settingsDefaultWeek?.desk_start_time
        ? setStartTimeDesk(settingsDefaultWeek.desk_start_time)
        : setStartTimeDesk(config.workday.defaultStartWorkingHour);
      settingsDefaultWeek && settingsDefaultWeek?.finish_time
        ? setFinishTime(settingsDefaultWeek.finish_time)
        : setFinishTime(config.workday.defaultFinishWorkingHour);
      settingsDefaultWeek && settingsDefaultWeek?.desk_finish_time
        ? setFinishTimeDesk(settingsDefaultWeek.desk_finish_time)
        : setFinishTimeDesk(config.workday.defaultFinishWorkingHour);
      settingsDefaultWeek && settingsDefaultWeek?.office
        ? setSelectedLocation(settingsDefaultWeek.office)
        : setSelectedLocation(userStore.me?.profile.default_office || null);
    }
  }, [settingsDefaultWeek]);

  useEffect(() => {
    if (editHoursLocationType === EditHoursLocationType.Planner) {
      setStartTime(
        schedule && schedule?.start_time
          ? schedule?.start_time
          : config.workday.defaultStartWorkingHour
      );
      setStartTimeDesk(
        schedule && schedule?.desk_start_time
          ? schedule?.desk_start_time
          : config.workday.defaultStartWorkingHour
      );

      setFinishTime(
        schedule && schedule?.finish_time
          ? schedule?.finish_time
          : config.workday.defaultFinishWorkingHour
      );
      setFinishTimeDesk(
        schedule && schedule?.desk_finish_time
          ? schedule?.desk_finish_time
          : config.workday.defaultFinishWorkingHour
      );

      schedule && schedule?.office && schedule.office.id
        ? setSelectedLocation(schedule.office.id)
        : setSelectedLocation(userStore.me?.profile.default_office || null);

      setSelectedSpace(schedule && schedule?.space && schedule.space.id ? schedule.space.id : 0);
    }
  }, [schedule]);

  useEffect(() => {
    buildingStore.loadBuildings();
    userStore.getMe();
  }, [buildingStore, userStore]);
  return (
    <>
      {
        <ConfirmDialog
          isOpen={isOpen}
          title={workType !== 1 && selectedSpace === 0 ? t("Edit Hours/Location") : t("Edit Hours")}
          primaryButtonText={t("Save")}
          secondaryButtonText={t("Cancel")}
          handleSecondaryClick={handleCancel}
          handlePrimaryClick={handleSave}
          dialogWidth="350px"
          disablePrimary={selectedLocation || workType === 1 ? false : true}
        >
          <Grid>
            {workType !== 1 && selectedSpace === 0 && (
              <Box>
                <Grid item>
                  <div className={classes.textFieldLabel}>{t("Location")}</div>
                </Grid>
                <Grid item>
                  <Autocomplete
                    id="locationAutoComplete"
                    options={[...buildingStore.buildings]}
                    getOptionLabel={(location) => `${location?.city}, ${location?.building_name}`}
                    autoComplete
                    defaultValue={
                      buildingStore.buildings.find(
                        (building) => building.id === selectedLocation
                      ) || null
                    }
                    onChange={(event, value) => {
                      setSelectedLocation(value?.id || null);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="location"
                        placeholder={`${t("Location")}...`}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Box>
            )}
            <Grid item>
              <div className={classes.textFieldLabel}>{t("Working Hours")}</div>
            </Grid>
            <Grid container direction="row" style={{ justifyContent: "space-between" }}>
              <Grid item lg={6}>
                <Select
                  id="startTime"
                  value={startTime}
                  className={classes.timeDropDown}
                  autoWidth
                  variant="outlined"
                  onChange={(event: any) => setStartTime(event.target.value)}
                >
                  {getTimes(15).map((time, idx) => {
                    return (
                      <MenuItem value={time.value} key={`StartTime${idx}`}>
                        {time.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item lg={6} style={{ textAlign: "end" }}>
                <Select
                  id="finishTime"
                  value={finishTime}
                  variant="outlined"
                  className={classes.timeDropDown}
                  autoWidth
                  onChange={(event: any) => setFinishTime(event.target.value)}
                >
                  {getTimes(15).map((time, idx) => {
                    return (
                      <MenuItem value={time.value} key={`FinishTime${idx}`}>
                        {time.key}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            {workType === 0 && selectedSpace === 0 && (
              <>
                <Grid item>
                  <div className={classes.textFieldLabel}>{t("Desk Hours")}</div>
                </Grid>
                <Grid container direction="row" style={{ justifyContent: "space-between" }}>
                  <Grid item lg={6}>
                    <Select
                      id="startTimeDesk"
                      value={startTimeDesk}
                      className={classes.timeDropDown}
                      autoWidth
                      variant="outlined"
                      onChange={(event: any) => setStartTimeDesk(event.target.value)}
                    >
                      {getTimes(15).map((time, idx) => {
                        return (
                          <MenuItem value={time.value} key={`deskStartTime${idx}`}>
                            {time.key}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item lg={6} style={{ textAlign: "end" }}>
                    <Select
                      id="finishTimeDesk"
                      value={finishTimeDesk}
                      className={classes.timeDropDown}
                      autoWidth
                      variant="outlined"
                      onChange={(event: any) => {
                        console.log("event.target.value", event.target.value);
                        setFinishTimeDesk(event.target.value);
                      }}
                    >
                      {getTimes(15).map((time, idx) => {
                        return (
                          <MenuItem value={time.value} key={`deskFinishTime${idx}`}>
                            {time.key}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </ConfirmDialog>
      }
    </>
  );
});

export default EditHoursLocationDialog;
