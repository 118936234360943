import { toJS } from "mobx";
import moment from "moment";
import BuildingStore from "stores/BuildingStore";
import RootStore from "stores/RootStore";

export enum BuildingType {
  OFFICE = 0,
  SITE = 1,
}

export interface Building {
  id: number;
  serraview_id?: number;
  city: string;
  country?: string;
  building_type?: BuildingType;
  building_name: string;
  display_name?: string;
  building_timezone?: string;
  total_capacity?: number;
  adjusted_capacity?: number | null;
  minimum_booking_time?: number | null;
  maximum_booking_time?: number | number;
  inter_booking_gap?: number | null;
  office_start_time?: string | null;
  office_end_time?: string | null;
  calibration_json?: string | null;
  floors?: Floor[];
}

export interface BuildingWithCapacity extends Building {
  date: string;
  planned_attendance?: number;
  booked_attendance?: number;
  remaining_availibility_percentage?: number;
  floors?: Floor[];
}

export interface BuildingList extends Building {
  floors?: Floor[];
}

export interface Floor {
  id: number;
  serraview_id?: number;
  name?: string;
  workpoints?: number;
  total_space_count?: number;
  available_space_count?: number;
  floor_teams_list?: string[];
  hasFloorPlan?: boolean;
  floor_plan?: string;
  calibration_json?: string;
  spaces?: Space[];
}

export interface FavouriteSpace {
  sv_id: number;
  serraview_id?: number;
  name: string;
  id?: number;
  building_id?: number;
  floor_id?: number;
  floor_name?: string;
  bookings?: SpaceBooking[];
}

export interface SpaceBooking {
  sv_id: number;
  user_id: number;
  date?: string;
  UTCTimeFrom: Date;
  UTCTimeTo: Date;
}

export interface TimePeriod {
  start: Date;
  end: Date;
}

export interface Space {
  id: number;
  name: string;
  serraview_id?: number;
  coordinates: { x: number; y: number };
  is_reserve_disabled?: boolean;
  is_flexible?: boolean;
  is_booked?: boolean;
  date?: string;
  user_id?: number;
  user_first_name?: string;
  user_last_name?: string;
  user_preferred_name?: string;
  user_is_first_aid_officer?: boolean;
  user_is_fire_warden?: boolean;
  team?: string;
  assigned_org_unit?: { id: number; name: string };
  booking_status?: string;
  corrected_coordinates_x?: number;
  corrected_coordinates_y?: number;
  space_type?: {
    id?: number;
    name?: string;
  };
  zone?: {
    id?: number;
    name?: string;
  };
  tags?: string[];
  // todo: add the other fields as we need them
  building?: Building;
}

export const DEFAULT_SPACE_RADIUS: number = 10;
export const DEFAULT_YRATIO: number = 1;
export const DEFAULT_SCALE_X: number = 0.25;
export const DEFAULT_SCALE_Y: number = 0.25;
export const DEFAULT_OFFSET_X: number = 0;
export const DEFAULT_OFFSET_Y: number = 0;

export interface CalibrationDetail {
  scale: { x: number; y: number };
  offset: { x: number; y: number };
  spaceRadius: number;
  yRatio?: number;
}

export interface FloorplanSpecs {
  scale: { x: number; y: number };
  offset: { x: number; y: number };
  spaceRadius: number;
  yRatio?: number;
  floor_specific?: {
    [floorId: string]: CalibrationDetail;
  };
}

export const floorplanSpecs: { buildingId: number; floorplanSpecs: FloorplanSpecs }[] = [
  {
    buildingId: 38,
    floorplanSpecs: {
      scale: { x: 0.54, y: 0.564 },
      offset: { x: 48, y: -15 },
      yRatio: 0.7322,
      spaceRadius: 15,
    },
  },
  {
    buildingId: 37,
    floorplanSpecs: {
      scale: { x: 0.2, y: 0.193 },
      offset: { x: 12, y: 0 },
      yRatio: 0.5865,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 34,
    floorplanSpecs: {
      scale: { x: 0.75, y: 0.79 },
      offset: { x: 60, y: -30 },
      yRatio: 0.63,
      spaceRadius: 15,
    },
  },
  {
    buildingId: 33,
    floorplanSpecs: {
      scale: { x: 0.472, y: 0.5 },
      offset: { x: 25, y: -10 },
      yRatio: 0.791,
      spaceRadius: 15,
    },
  },
  {
    buildingId: 32,
    floorplanSpecs: {
      scale: { x: 0.306, y: 0.3 },
      offset: { x: 12, y: 0 },
      yRatio: 0.9726,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 31,
    floorplanSpecs: {
      scale: { x: 0.262, y: 0.261 },
      offset: { x: 30, y: 0 },
      yRatio: 0.5107,
      spaceRadius: DEFAULT_SPACE_RADIUS,
    },
  },
  {
    buildingId: 25,
    floorplanSpecs: {
      scale: { x: 0.254, y: 0.253 },
      offset: { x: 38, y: 0 },
      yRatio: 0.4858,
      spaceRadius: DEFAULT_SPACE_RADIUS,
      floor_specific: {
        [169]: {
          offset: { x: 36, y: 0 },
          scale: { x: 0.255, y: 0.256 },
          yRatio: 0.4858,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
        [168]: {
          offset: { x: 36, y: 0 },
          scale: { x: 0.221, y: 0.256 },
          yRatio: 0.4858,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
      },
    },
  },
  {
    buildingId: 24,
    floorplanSpecs: {
      scale: { x: 0.227, y: 0.225 },
      offset: { x: 35, y: 2 },
      yRatio: 0.4033,
      spaceRadius: DEFAULT_SPACE_RADIUS,
      floor_specific: {
        [159]: {
          offset: { x: 22, y: 5 },
          scale: { x: 0.212, y: 0.21 },
          yRatio: 0.6201,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
      },
    },
  },
  {
    buildingId: 23,
    floorplanSpecs: {
      scale: { x: 0.24, y: 0.242 },
      offset: { x: 28, y: 0 },
      yRatio: 0.5732,
      spaceRadius: 10,
      floor_specific: {
        [155]: {
          scale: { x: 0.22, y: 0.23 },
          offset: { x: 30, y: 10 },
          yRatio: 0.5723,
          spaceRadius: 10,
        },
      },
    },
  },
  {
    buildingId: 22,
    floorplanSpecs: {
      scale: { x: 0.276, y: 0.279 },
      offset: { x: 2, y: -13 },
      yRatio: 1.1465,
      spaceRadius: 10,
      floor_specific: {
        [153]: {
          scale: { x: 0.276, y: 0.273 },
          offset: { x: -0, y: 0 },
          yRatio: 1.1465,
          spaceRadius: 10,
        },
      },
    },
  },
  {
    buildingId: 19,
    floorplanSpecs: {
      scale: { x: 0.1963, y: 0.196 },
      offset: { x: 42, y: 0 },
      yRatio: 0.3242,
      spaceRadius: 8,
      floor_specific: {
        [139]: {
          scale: { x: 0.206, y: 0.205 },
          offset: { x: 60, y: 0 },
          yRatio: 0.2529,
          spaceRadius: 7,
        },
        [141]: {
          scale: { x: 0.1925, y: 0.193 },
          offset: { x: 42, y: 0 },
          yRatio: 0.3174,
          spaceRadius: 8,
        },
        [142]: {
          scale: { x: 0.1955, y: 0.195 },
          offset: { x: 42, y: 0 },
          yRatio: 0.3242,
          spaceRadius: 8,
        },
        [143]: {
          scale: { x: 0.1955, y: 0.195 },
          offset: { x: 42, y: 0 },
          yRatio: 0.3242,
          spaceRadius: 8,
        },
        [144]: {
          scale: { x: 0.1955, y: 0.195 },
          offset: { x: 42, y: 0 },
          yRatio: 0.3242,
          spaceRadius: 8,
        },
        [145]: {
          scale: { x: 0.2074, y: 0.206 },
          offset: { x: 44, y: 0 },
          yRatio: 0.3223,
          spaceRadius: 8,
        },
        [146]: {
          scale: { x: 0.196, y: 0.197 },
          offset: { x: 43, y: -2 },
          yRatio: 0.3223,
          spaceRadius: 8,
        },
        [147]: {
          scale: { x: 0.2445, y: 0.225 },
          offset: { x: 45, y: 20 },
          yRatio: 0.3223,
          spaceRadius: 8,
        },
        [185]: {
          scale: { x: 0.213, y: 0.215 },
          offset: { x: 45, y: -2 },
          yRatio: 0.3226,
          spaceRadius: 8,
        },
        [186]: {
          scale: { x: 0.2135, y: 0.215 },
          offset: { x: 44, y: -2 },
          yRatio: 0.3213,
          spaceRadius: 8,
        },
        [199]: {
          scale: { x: 0.213, y: 0.215 },
          offset: { x: 45, y: -2 },
          yRatio: 0.3226,
          spaceRadius: 8,
        },
        [200]: {
          scale: { x: 0.2135, y: 0.215 },
          offset: { x: 44, y: -2 },
          yRatio: 0.3213,
          spaceRadius: 8,
        },
        [202]: {
          scale: { x: 0.2445, y: 0.225 },
          offset: { x: 45, y: 20 },
          yRatio: 0.3223,
          spaceRadius: 8,
        },
      },
    },
  },
  {
    buildingId: 15,
    floorplanSpecs: {
      scale: { x: 0.2325, y: 0.231 },
      offset: { x: 17, y: 0 },
      yRatio: 0.7363,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 14,
    floorplanSpecs: {
      scale: { x: 0.29, y: 0.285 },
      offset: { x: 18, y: 2 },
      yRatio: 0.7811,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 13,
    floorplanSpecs: {
      scale: { x: 0.253, y: 0.2525 },
      offset: { x: 10, y: 0 },
      yRatio: 0.9844,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 11,
    floorplanSpecs: {
      scale: { x: 0.427, y: 0.428 },
      offset: { x: 21, y: -1 },
      yRatio: 0.9473,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 10,
    floorplanSpecs: {
      scale: { x: 0.389, y: 0.385 },
      offset: { x: 48, y: 8 },
      yRatio: 0.5576,
      spaceRadius: 10,
      floor_specific: {
        [75]: {
          scale: { x: 0.353, y: 0.363 },
          offset: { x: 40, y: -12 },
          yRatio: 0.5527,
          spaceRadius: 10,
        },
        [76]: {
          scale: { x: 0.341, y: 0.342 },
          offset: { x: 30, y: -5 },
          yRatio: 0.5566,
          spaceRadius: 10,
        },
        [77]: {
          scale: { x: 0.355, y: 0.35 },
          offset: { x: 40, y: 5 },
          yRatio: 0.5537,
          spaceRadius: 10,
        },
      },
    },
  },
  {
    buildingId: 9,
    floorplanSpecs: {
      scale: { x: 0.275, y: 0.276 },
      offset: { x: 10, y: 0 },
      yRatio: 0.9355,
      spaceRadius: 10,
      floor_specific: {
        [74]: {
          scale: { x: 0.272, y: 0.263 },
          offset: { x: 11, y: 28 },
          yRatio: 0.9248,
          spaceRadius: 10,
        },
      },
    },
  },
  {
    buildingId: 8,
    floorplanSpecs: {
      scale: { x: 0.68, y: 0.673 },
      offset: { x: 29, y: 0 },
      yRatio: 0.9268,
      spaceRadius: 20,
    },
  },
  {
    buildingId: 7,
    floorplanSpecs: {
      scale: { x: 0.21, y: 0.213 },
      offset: { x: 22, y: 0 },
      yRatio: 0.6094,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 6,
    floorplanSpecs: {
      scale: { x: 0.32, y: 0.32 },
      offset: { x: 25, y: 0 },
      yRatio: 0.7354,
      spaceRadius: 10,
    },
  },
  {
    buildingId: 5,
    floorplanSpecs: {
      scale: { x: 0.257, y: 0.257 },
      offset: { x: 18, y: 0 },
      yRatio: 0.7725,
      spaceRadius: 10,
      floor_specific: {
        [63]: {
          scale: { x: 0.27, y: 0.27 },
          offset: { x: 18, y: 0 },
          yRatio: 0.7568,
          spaceRadius: 10,
        },
        [64]: {
          scale: { x: 0.27, y: 0.27 },
          offset: { x: 18, y: 0 },
          yRatio: 0.7568,
          spaceRadius: 10,
        },
        [65]: {
          scale: { x: 0.27, y: 0.27 },
          offset: { x: 18, y: 0 },
          yRatio: 0.7568,
          spaceRadius: 10,
        },
      },
    },
  },
  {
    buildingId: 2,
    floorplanSpecs: {
      scale: { x: 0.241, y: 0.2427 },
      offset: { x: 25, y: 0 },
      yRatio: 0.5732,
      spaceRadius: DEFAULT_SPACE_RADIUS,
      floor_specific: {
        [34]: {
          offset: { x: 25, y: 0 },
          scale: { x: 0.241, y: 0.2427 },
          yRatio: 0.5576,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
      },
    },
  },
  {
    buildingId: 1,
    floorplanSpecs: {
      scale: { x: 0.185, y: 0.188 },
      offset: { x: 29, y: -4 },
      yRatio: 0.4169921875,
      spaceRadius: DEFAULT_SPACE_RADIUS,
    },
  },
  {
    buildingId: 3,
    floorplanSpecs: {
      scale: { x: 0.2235, y: 0.211 },
      offset: { x: 10, y: 0 },
      spaceRadius: 10,
      yRatio: 0.577,
      floor_specific: {
        [43]: {
          scale: { x: 0.2235, y: 0.211 },
          offset: { x: 10, y: -20 },
          yRatio: 0.577,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
        [47]: {
          offset: { x: -8, y: 0 },
          scale: { x: 0.2235, y: 0.205 },
          yRatio: 0.577,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
        [48]: {
          offset: { x: 60, y: 2 },
          scale: { x: 0.2235, y: 0.2275 },
          yRatio: 0.577,
          spaceRadius: DEFAULT_SPACE_RADIUS,
        },
      },
    },
  },
];
