import Office from "views/main/Office";
import { MdList, MdDashboard, MdPeople, MdPlace, MdPerson, MdInfoOutline } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";

import Dashboard from "views/main/Dashboard";
import Teams from "views/main/Teams";
import People from "views/main/People";
import Schedule from "views/main/Schedule";
import Notifications from "views/main/Notifications";
import Profile from "views/main/Profile";
import Booking from "views/main/Booking";
import TeamsDetails from "views/main/TeamsDetails";
import Feedback from "views/main/Feedback";
import InfoAndSupport from "views/main/InfoAndSupport";
import CalibrationTool from "views/main/CalibrationTool";
import VisitorCheckin from "views/main/Office";
import CiscoMeetingSpaces from "views/main/Office";
import Tester from "views/main/Tester";
import Requests from "views/main/Requests";
import Meetings from "views/main/Meetings";
import MeetingDetails from "components/meetings/MeetingDetails";

export const primaryRoutes = [
  { path: "/dashboard", icon: MdDashboard, name: "Dashboard", component: Dashboard, onNav: true },
  {
    path: "/tester",
    icon: MdDashboard,
    name: "Tester",
    component: Tester,
    onNav: false,
  },
  {
    path: "/calibrator",
    icon: MdDashboard,
    name: "Calibrator",
    component: CalibrationTool,
    onNav: false,
  },
  {
    path: "/calibrator/:buildingId",
    icon: MdDashboard,
    name: "Calibrator",
    component: CalibrationTool,
    onNav: false,
  },
  {
    path: "/calibrator/:buildingId/:floorId",
    icon: MdDashboard,
    name: "Calibrator",
    component: CalibrationTool,
    onNav: false,
  },
  {
    path: "/my-planner",
    icon: IoCalendarOutline,
    name: "My Planner",
    component: Schedule,
    onNav: true,
  },
  {
    path: "/my-planner/bookings",
    name: "My Planner",
    component: Schedule,
    onNav: false,
  },
  {
    path: "/my-planner/leave",
    name: "My Planner",
    component: Schedule,
    onNav: false,
  },
  { path: "/teams", icon: MdPeople, name: "My Teams", component: Teams, onNav: true },
  {
    path: "/teams/:teamId",
    name: "Team Information",
    component: TeamsDetails,
    onNav: false,
  },
  {
    path: "/teams/planner/:teamId",
    name: "Team Detail - Planner",
    component: TeamsDetails,
    onNav: false,
  },
  {
    path: "/teams/information/:teamId",
    name: "Team Information",
    component: TeamsDetails,
    onNav: false,
  },
  {
    path: "/teams/noticeboard/:teamId",
    name: "Team noticeboard",
    component: TeamsDetails,
    onNav: false,
  },
  {
    path: "/teams/leave/:teamId",
    name: "Team Leave",
    component: TeamsDetails,
    onNav: false,
  },
  {
    path: "/teams/ilo/:teamId",
    name: "Team ILO",
    component: TeamsDetails,
    onNav: false,
  },
  {
    path: "/teams/people/:teamId",
    name: "Team People",
    component: TeamsDetails,
    onNav: false,
  },
  { path: "/offices", icon: MdPlace, name: "BHP Locations", component: Office, onNav: true },
  {
    path: "/offices/:officeId/:tab?/:eventId?",
    icon: MdPlace,
    name: "Show Office",
    component: Office,
    onNav: false,
  },
  { path: "/people", icon: MdPerson, name: "People", component: People, onNav: true },
  { path: "/requests", icon: MdList, name: "Requests", component: Requests, onNav: true },
  { path: "/requests/open", name: "Requests", component: Requests, onNav: false },
  { path: "/requests/closed", name: "Requests", component: Requests, onNav: false },
  { path: "/meetings", icon: MdList, name: "Meetings", component: Meetings, onNav: true },
  { path: "/meetings/open", name: "Meetings", component: Meetings, onNav: false },
  {
    path: "/meeting-details/:meetingId",
    name: "Meeting Details",
    component: MeetingDetails,
    onNav: false,
  },
  {
    path: "/people/:peopleId",
    icon: MdPerson,
    name: "Show People",
    component: People,
    onNav: false,
  },
  {
    path: "/booking",
    name: "Booking",
    component: Booking,
    onNav: false,
  },
  {
    path: "/booking/:date",
    name: "Booking",
    component: Booking,
    onNav: false,
  },
  {
    path: "/booking/:date/:buildingId",
    name: "Booking",
    component: Booking,
    onNav: false,
  },
  {
    path: "/infoandsupport/:tab",
    icon: MdInfoOutline,
    name: "Support",
    component: InfoAndSupport,
    onNav: false,
  },
  {
    path: "/visitor-checkin",
    icon: MdPlace,
    name: "Visitor Check-In",
    component: VisitorCheckin,
    onNav: false,
  },
  {
    path: "/cisco-meetings",
    icon: MdPlace,
    name: "Cisco Meetingspace",
    component: CiscoMeetingSpaces,
    onNav: false,
  },
];

export const secondaryRoutes = [
  { path: "/profile", icon: null, name: "Profile", component: Profile, onNav: true },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    onNav: true,
  },
  {
    path: "/feedback",
    icon: null,
    name: "Feedback",
    component: Feedback,
    onNav: true,
  },
  {
    path: "/feedback/:feedbackId",
    icon: null,
    name: "Feedback",
    component: Feedback,
    onNav: false,
  },
];
