import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  Paper,
  Snackbar,
  Link,
  Tooltip,
  Radio,
  Select,
  MenuItem,
  Input,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormHelperText,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import {
  useBuildingStore,
  useUserStore,
  usePeopleStore,
  useVisitorStore,
  useBAMStore,
} from "providers/RootStoreProvider";
import { useEffect, useState, useCallback, Fragment } from "react";
import { useHistory } from "react-router";
import { bhpColor, settings, displaySettings } from "styles/globals";
import { useTranslation } from "react-i18next";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import HeaderImage from "assets/headerImage.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { PeopleName } from "interfaces/People";
import { peopleStyles } from "styles/peopleStyles";
import { debounce } from "lodash";
import moment from "moment";
import { toJS } from "mobx";
import { teamStyles } from "styles/teamStyles";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { Form_prequisite, Special_Access_Area } from "stores/BAMStore";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import InformationIcon from "components/utils/InformationIcon";
import PhotoSpecImage from "assets/photoSpec.png";
import {
  BuildingAcccessEmployeeModel,
  BuildingAcccessExternalVisitorModel,
  BuildingAcccessInternalVisitorModel,
  BuildingAcccessReplacementCardModel,
  BuildingAcccessShortTermEmployeeModel,
  BuildingAcccessSpecialAccessModel,
  BuildingAcccessVMSExternalVisitorModel,
  GallagherVMSIntergationModel,
  CardType,
  FormElement,
  getDefaultCardTypeBlurb,
  getLocalDate,
} from "interfaces/Visitor";
import LoadingZone from "components/schedule/LoadingZone";
import { CompareArrowsOutlined, FlashOnRounded } from "@material-ui/icons";
import { toNamespacedPath } from "path/posix";
import { getHolidays } from "utils/holidays";

// #region STYLES
const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    form: {
      "& .MuiGrid-container": {
        margin: "20px",
        marginLeft: "10px",
        marginRight: "10px",
      },
    },
    mainTable: {
      "& td": {
        // borderBottom: 0,
      },
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 16,
      display: "inline-block",
    },
    wrapper: {
      marginTop: theme.spacing(2),
    },
    team: {
      height: 36,
      width: "calc(100% - 150px)",
      paddingLeft: theme.spacing(1),
      backgroundColor: `${bhpColor.blueGrey4}50`,
      display: "inline-flex",
      verticalAlign: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    datePicker: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.grey3}`,
      backgroundColor: "white",
      "& .MuiFormLabel-root": {
        color: bhpColor.blueGrey1,
        fontSize: 16,
        fontWeight: 700,
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 10px)",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    selectBox: {
      width: "calc(100% - 150px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 10px)",
      },
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    buttonRow: {
      marginTop: theme.spacing(2),
      textAlign: "start",
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    base: {
      color: bhpColor.blueGrey1,
      "& .MuiPaper-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDialog-paper": {
        maxWidth: "calc(100% - 64px)",
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
      },
    },
    dialogTitle: {
      backgroundImage: `url(${HeaderImage})`,
      backgroundSize: "100% auto",
      color: bhpColor.white,
      "& h2": {
        fontWeight: "bold",
        "& svg": {
          verticalAlign: "text-top",
        },
      },
    },
    content: {
      marginBottom: theme.spacing(2),
    },
    mainItem: {
      flex: "auto",
    },
    bottomItem: {
      paddingBottom: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    anchorDayButton: {
      fontSize: 20,
      color: bhpColor.blueGrey1,
      borderRadius: settings.borderRadius,
      borderWidth: 2,
      borderColor: bhpColor.blueGrey1,
      borderStyle: "solid",
      backgroundColor: bhpColor.blueGrey4,
      textTransform: "none",
      "&.selected": {
        backgroundColor: bhpColor.blueGrey1,
        color: bhpColor.white,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        height: 50,
        width: 50,
        marginRight: 6,
        minWidth: 50,
      },
    },
  })
);
// #endregion STYLES

interface VisitorRegistrationProps {
  isRequestAccess?: boolean;
}

interface User {
  email: string;
  firstname: string;
  lastname: string;
  company: string;
  phoneNumber: string;
}

const VisitiorRegistration = observer((props: VisitorRegistrationProps) => {
  const { isRequestAccess = false } = props;

  const SV_BuildingsIDsToInclude: number[] = [
    50, 76, 7, 61, 34, 35, 6, 5, 51, 37, 70, 48, 131, 136,
  ];

  const userStore = useUserStore();
  const bamStore = useBAMStore();
  const classes = innerStyles();
  const teamClasses = teamStyles();
  const { t, i18n } = useTranslation();
  const [showFloorAndLocation, setShowFloorAndLocation] = useState(false);
  const [showSelectedDesk, setShowSelectedDesk] = useState(false);
  const [selectedDeskContent, setSelectedDeskContent] = useState("");
  const peopleClasses = peopleStyles();

  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const buildingStore = useBuildingStore();
  const peopleStore = usePeopleStore();
  const visitorStore = useVisitorStore();
  const history = useHistory();
  const [emailOptions, setEmailOptions] = useState<User[]>([]);
  const [disableUpdateFields, setDisableUpdateFields] = useState(false);
  const charLimit = 250;

  type initialFormStateType = {
    locationId: number;
    emailId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    fromDateTime: string;
    toDateTime: string;
    reasonForVisit: string;
    accessArea: string[];
    accessBuildingHours: boolean;
    buildingHours: number;
    accessReason: string;
    hostNameId: number;
    cardHolderId: number;
    supervisorId: number;
    notifyVisitor: boolean;
    bookADesk: boolean;
    floorId: number;
    spaceId: number;
    photo: any;
    reasonForReplacement: string;
    additionalInfo: string;
    vmsAccessArea: string;
    meetingName: string;
    meetingDescription?: string;
    mobileCredentials: boolean;
    under18?: boolean;
    meetingRoom: string;
  };

  const initialFormState: initialFormStateType = {
    locationId: 0,
    emailId: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    fromDateTime: "",
    toDateTime: "",
    reasonForVisit: "",
    accessReason: "",
    accessArea: [],
    accessBuildingHours: true,
    buildingHours: 0,
    hostNameId: 0,
    cardHolderId: 0,
    supervisorId: 0,
    notifyVisitor: false,
    bookADesk: false,
    floorId: 0,
    spaceId: 0,
    photo: null,
    reasonForReplacement: "",
    additionalInfo: "",
    vmsAccessArea: "",
    meetingName: "",
    meetingDescription: "",
    mobileCredentials: true,
    under18: false,
    meetingRoom: "",
  };

  const registrationFormFields: {
    [formName: string]: FormElement[];
  } = {
    "External Visitor (no pass required)": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "emailId",
        helperText: "You need to provide an email address",
        isRequired: true,
      },
      { fieldName: "firstName", helperText: "You need to enter a first name", isRequired: true },
      { fieldName: "lastName", helperText: "You need to choose a last name", isRequired: true },
      {
        fieldName: "companyName",
        helperText: "You need to provide a company name",
        isRequired: true,
      },
      {
        fieldName: "phoneNumber",
        helperText: "You need to provide a phone number",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: true },
      {
        fieldName: "accessAreaId",
        helperText: "You need to choose an access area",
        isRequired: false,
      },
      {
        fieldName: "accessReason",
        helperText: "You need to specify a reason for access",
        isRequired: false,
      },
      {
        fieldName: "reasonForVisit",
        helperText: "You need to enter the reason for the visit",
        isRequired: true,
      },
    ],
    "External Visitor (pass required)": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "emailId",
        helperText: "You need to provide an email address",
        isRequired: true,
      },
      { fieldName: "firstName", helperText: "You need to enter a first name", isRequired: true },
      { fieldName: "lastName", helperText: "You need to choose a last name", isRequired: true },
      {
        fieldName: "companyName",
        helperText: "You need to supply a company name",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      {
        fieldName: "phoneNumber",
        helperText: "You need to choose a phone number",
        isRequired: true,
      },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: true },
      {
        fieldName: "accessReason",
        helperText: "You need to specify a reason for access",
        isRequired: false,
      },
      {
        fieldName: "accessAreaId",
        helperText: "You need to choose an access area",
        isRequired: false,
      },
      {
        fieldName: "preferredFloorORMeetingRoomNumber",
        helperText: "You need to enter the preferred floor/meeting room number",
        isRequired: false,
      },
      {
        fieldName: "reasonForVisit",
        helperText: "You need to enter the reason for the visit",
        isRequired: true,
      },
    ],
    "Internal Visitor": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "cardHolderId",
        helperText: "You need to choose a card holder",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: true },
      {
        fieldName: "reasonForVisit",
        helperText: "You need to enter the reason for the visit",
        isRequired: true,
      },
      {
        fieldName: "accessAreaId",
        helperText: "You need to choose an access area",
        isRequired: false,
      },
      {
        fieldName: "accessReason",
        helperText: "You need to specify a reason for access",
        isRequired: false,
      },
    ],
    "Short-Term employee / Contractor": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "cardHolderId",
        helperText: "You need to choose a card holder",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: true },
      {
        fieldName: "accessAreaId",
        helperText: "You need to choose an access area",
        isRequired: false,
      },
      {
        fieldName: "accessReason",
        helperText: "You need to specify a reason for access",
        isRequired: false,
      },
      {
        fieldName: "photo",
        helperText: "A photo is required for Reception to issue an Access Pass",
        isRequired: true,
      },
      {
        fieldName: "additionalInfo",
        helperText: "Any additional information ?",
        isRequired: false,
      },
    ],
    "Employee / Embedded Contractor": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "cardHolderId",
        helperText: "You need to choose a card holder",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: false },
      {
        fieldName: "accessReason",
        helperText: "You need to specify a reason for access",
        isRequired: false,
      },
      {
        fieldName: "accessAreaId",
        helperText: "You need to choose an access area",
        isRequired: false,
      },
      {
        fieldName: "photo",
        helperText: "A photo is required for Reception to issue an Access Pass",
        isRequired: true,
      },
      {
        fieldName: "additionalInfo",
        helperText: "Any additional information?",
        isRequired: false,
      },
    ],
    "Special Access Area": [
      {
        fieldName: "locationId",
        helperText: "You need to choose a location",
        isRequired: true,
      },
      {
        fieldName: "cardHolderId",
        helperText: "You need to choose a card holder",
        isRequired: true,
      },
      {
        fieldName: "supervisorId",
        helperText: "You need to nominate a supervisor",
        isRequired: true,
      },
      {
        fieldName: "fromDateTime",
        helperText: "You need to choose a start time",
        isRequired: true,
      },
      {
        fieldName: "toDateTime",
        helperText: "You need to choose an end time",
        isRequired: false,
      },
      {
        fieldName: "accessHours",
        helperText: "You need to choose an access hours",
        isRequired: true,
      },
      {
        fieldName: "accessAreaId",
        helperText: "You need to choose an access area",
        isRequired: true,
      },
      {
        fieldName: "accessReason",
        helperText: "You need to specify a reason for access",
        isRequired: true,
      },
    ],
    "Replacement Card": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "cardHolderId",
        helperText: "You need to choose a card holder",
        isRequired: false,
      },
      {
        fieldName: "companyName",
        helperText: "You need to supply a company name",
        isRequired: false,
      },
      {
        fieldName: "reasonForReplacement",
        helperText: "You need to enter the reason for the replacement",
        isRequired: true,
      },
    ],
    // VMS Gallagher external visitor form fields
    "External Visitor [pass required]": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "emailId",
        helperText: "You need to provide an email address",
        isRequired: true,
      },
      { fieldName: "firstName", helperText: "You need to enter a first name", isRequired: true },
      { fieldName: "lastName", helperText: "You need to choose a last name", isRequired: true },
      {
        fieldName: "companyName",
        helperText: "You need to enter the company name",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      {
        fieldName: "phoneNumber",
        helperText: "You need to enter the phone number",
        isRequired: true,
      },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: true },
      {
        fieldName: "meetingName",
        helperText: "You need to enter the name of the meeting",
        isRequired: true,
      },
      {
        fieldName: "meetingDescription",
        helperText: "You need to enter the description of the meeting",
        isRequired: true,
      },
      {
        fieldName: "vmsAccessArea",
        helperText: "You have to Select the Visit Category",
        isRequired: true,
      },
      {
        fieldName: "mobileCredentials",
        helperText: "You can request Mobile Access",
        isRequired: false,
      },
      {
        fieldName: "under 18?",
        helperText: "You need to enable the check box if the visitor's age is under 18",
        isRequired: true,
      },
      {
        fieldName: "Meeting Room",
        helperText: "You need to specify the meeting room details",
        isRequired: false,
      },
    ],
  };

  const initialFormErrorState = {
    locationIdError: false,
    firstNameError: false,
    lastNameError: false,
    emailIdError: false,
    fromDateTimeError: false,
    toDateTimeError: false,
    hostId: false,
    cardHolderId: false,
    supervisorId: false,
    floorIdError: false,
    spaceIdError: false,
    companyNameError: false,
    phoneNumberError: false,
    reasonForVisitError: false,
    accessReasonError: false,
    accessAreaError: false,
    hostNameIdError: false,
    photoError: false,
    additionalInfo: false,
    reasonForReplacement: false,
    preferredFloorORMeetingRoomNumber: false,
    vmsAccessArea: false,
    meetingName: false,
    meetingDescription: false,
    meetingRoom: false,
  };

  const initialFormHelperTextState = {
    locationIdHelperText: "",
    firstNameHelperText: "",
    lastNameHelperText: "",
    emailIdHelperText: "",
    fromDateTimeHelperText: "",
    toDateTimeHelperText: "",
    hostId: "",
    cardHolderId: "",
    supervisorId: "",
    floorIdHelperText: "",
    spaceIdHelperText: "",
    companyNameHelperText: "",
    phoneNumberHelperText: "",
    reasonForVisitHelperText: "",
    accessReasonHelperText: "",
    accessAreaHelperText: "",
    hostNameIdHelperText: "",
    photoHelperText: "",
    additionalInfo: "",
    reasonForReplacement: "",
    preferredFloorORMeetingRoomNumber: "",
    vmsAccessArea: "",
    meetingName: "",
    meetingDescription: "",
    meetingRoom: "",
  };

  const [visitorRegistrationFormValues, setVisitorRegistrationFormValues] =
    useState<object>(initialFormState);
  const [visitorRegistrationErrorValues, setVisitorRegistrationErrorValues] =
    useState<object>(initialFormErrorState);
  const [visitorRegistrationHelperTextValues, setVisitorRegistrationHelperTextValues] =
    useState<object>(initialFormHelperTextState);
  const [showExternalRegistrationLink, setShowExternalRegistrationLink] = useState("none");
  const [showRegistrationForm, setShowRegistrationForm] = useState("block");
  const [accessPassCheckboxOn, setAccessPassCheckboxOn] = useState(false);
  const [accessPassCheckboxEditable, setAccessPassCheckboxEditable] = useState(false);

  const [floorArray, setFloorArray] = useState<any>([]);
  const [spaceArray, setSpaceArray] = useState<any>([]);

  const setFormValues = (fieldName: string, value: any) => {
    let formValues = { ...visitorRegistrationFormValues };
    let errorText = { ...visitorRegistrationHelperTextValues };
    let errorState = { ...visitorRegistrationErrorValues };
    formValues[fieldName] = value;
    errorText[fieldName] = "";
    errorState[fieldName] = false;
    setVisitorRegistrationFormValues(formValues);
    setVisitorRegistrationErrorValues(errorState);
    setVisitorRegistrationHelperTextValues(errorText);
  };

  const externalRegistrationLinks = [
    {
      id: 96,
      external_link: "https://sar.bhp.com/",
    },
    {
      id: 97,
      external_link: "https://sar.bhp.com/",
    },
    {
      id: 98,
      external_link:
        "https://bhpb-b57f6c15a22f0b.sharepoint.com/sites/GRPHR480Q/FormsApp/UFRuntime.aspx?remoteAppUrl=https://formso365.nintex.com&SPAppWebUrl=https://BHPB-b57f6c15a22f0b.sharepoint.com/sites/GRPHR480Q/FormsApp&SPHostUrl=https://spo.bhpbilliton.com/sites/GRPHR480Q/&ctype=0x01009137F4DFB0AC7F4FAAE2C231A8805ED6&amode=0&List=455904f2-ca3f-477f-868b-1407807a423b&Source=https://spo.bhpbilliton.com/sites/GRPHR480Q/Lists/AccessRequests/MyRequests.aspx&Web=f7fbf9ee-5be1-42f1-96f4-8aae9e538942",
    },
    {
      id: 99,
      external_link: "http://forms.bhp.com/property/visitor",
    },
  ];

  const [externalLink, setExternalLink] = useState<string | undefined>("");

  const [displayVisitorDetailField, setDisplayVisitorDetailField] = useState<boolean>(true);

  const checkEmailId = (event) => {
    let email_value: string = event.target.value;

    if (email_value !== "") {
      // let email_rule = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,4}$/;
      let email_rule = /^\w+[-+_%\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,4}$/;
      if (email_rule.test(email_value) == true) {
        (async () => {
          const visitorEmail = {
            email_id: email_value,
          };
          const response = await visitorStore.validateVisitorEmail(visitorEmail);
          if (response.status == 200) {
            setDisplayVisitorDetailField(false);
          }
        })();
      }
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    let formValues = { ...visitorRegistrationFormValues };
    let errorText = { ...visitorRegistrationHelperTextValues };
    let errorState = { ...visitorRegistrationErrorValues };

    if (name.startsWith("emailId")) formValues[name] = `${event.target.value}`.toLowerCase();

    if (
      name.startsWith("firstName") ||
      name.startsWith("lastName") ||
      name.startsWith("companyName") ||
      name.startsWith("phoneNumber") ||
      name.startsWith("floorId") ||
      name.startsWith("spaceId") ||
      name.startsWith("reasonForReplacement") ||
      name.startsWith("additionalInfo") ||
      name.startsWith("preferredFloorORMeetingRoomNumber") ||
      name.startsWith("meetingName") ||
      name.startsWith("meetingRoom") ||
      name.startsWith("vmsAccessArea")
    ) {
      formValues[name] = event.target.value;
      errorText[name] = "";
      errorState[name] = false;
    }

    switch (name) {
      case "cardTypeId":
        setCardType(event.target.value);
        setRequiredFormFields(event.target.value);
        formValues["cardTypeId"] = event.target.value;
        //setShowRegistrationForm("none");
        setSpecialAccessPass(`${event.target.value}` === `${CardType["Special Access Area"]}`);
        errorText["cardTypeId"] = "";
        errorState["cardTypeId"] = false;
        clearForm();
        break;
      case "locationId":
        formValues["locationId"] = event.target.value;
        const newLocation = event.target.value;
        setCardTypesForLocation(bamStore.location_cardTypes[newLocation]);
        setCardType(CardType.None);
        setLocationAccessAreas(
          bamStore.location_saas[newLocation] &&
            bamStore.location_saas[newLocation].filter((area) => area.vms_access_group === false)
        );
        setVMSLocationAccessAreas(bamStore.vms_location_saas[newLocation]);
        if (event.target.value == 96 || event.target.value == 98 || event.target.value == 99) {
          let external_link_url = externalRegistrationLinks.find(
            (item) => item.id == event.target.value
          );
          setExternalLink(external_link_url?.external_link);
          setShowExternalRegistrationLink("block");
          setShowRegistrationForm("none");
        } else {
          const buildingValues = buildingStore.getBuilding(event.target.value);
          setFloorArray(toJS(buildingValues?.floors));
        }
        if (
          event.target.value == 1 ||
          event.target.value == 14 ||
          event.target.value == 24 ||
          event.target.value == 13 ||
          event.target.value == 15
        ) {
          setAccessPassCheckboxOn(true);
          setRequireAccessPass(true);
          setAccessPassCheckboxEditable(true);
        } else {
          setAccessPassCheckboxOn(false);
          setRequireAccessPass(false);
          setAccessPassCheckboxEditable(false);
        }
        errorText["locationId"] = "";
        errorState["locationId"] = false;
        break;
      case "fromDateTime":
        formValues[name] = event.target.value;
        if (cardType == 9) {
          let startDateTime = event.target.value;
          const [startDateValues, startTimeValues] = startDateTime.split("T");
          const [startHhours, startMinutes] = startTimeValues.split(":");
          if (startHhours < 7) setVmsStartDateValidation(true);
          else setVmsStartDateValidation(false);
        }
        errorText[name] = "";
        errorState[name] = false;
        break;
      case "toDateTime":
        formValues[name] = event.target.value;
        if (cardType == 9) {
          let endDateTime = event.target.value;
          const [endDateValues, endTimeValues] = endDateTime.split("T");
          const [endHours, endMinutes] = endTimeValues.split(":");
          if (endHours >= 17 && endMinutes > 0) setVmsEndDateValidation(true);
          else setVmsEndDateValidation(false);
        }
        errorText[name] = "";
        errorState[name] = false;
        break;
      case "firstName":
      case "lastName":
      case "toDateTime":
      case "companyName":
      case "phoneNumber":
      case "preferredFloorORMeetingRoomNumber":
      case "reasonForVisit":
      case "meetingDescription":
      case "accessArea":
        formValues[name] = event.target.value;
        errorText[name] = "";
        errorState[name] = false;
        break;
      case "accessReason":
        formValues[name] = event.target.value;
        errorText[name] = "";
        errorState[name] = false;
        break;
      case "bookADesk":
        let isChecked = event.target.checked;
        formValues["bookADesk"] = isChecked;
        if (isChecked) {
          setShowFloorAndLocation(true);
        } else {
          setShowFloorAndLocation(false);
        }
        break;
      case "notifyVisitor":
        let isNotifyVisitorChecked = event.target.checked;
        if (isNotifyVisitorChecked) {
          formValues["notifyVisitor"] = true;
        } else {
          formValues["notifyVisitor"] = false;
        }
        break;
      case "floorId":
        if (formValues["bookADesk"]) {
          if (event.target.value) {
            formValues["floorId"] = event.target.value;
            errorText["floorId"] = "";
            errorState["floorId"] = false;

            let fromDate = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
            let toDate = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
            if (visitorRegistrationFormValues["fromDateTime"] != "") {
              fromDate = moment(visitorRegistrationFormValues["fromDateTime"]).format(
                "YYYY-MM-DDTHH:MM:SS"
              );
              fromDate = convert_date_time_iso_format(fromDate);
            }
            if (visitorRegistrationFormValues["toDateTime"] != "") {
              toDate = moment(visitorRegistrationFormValues["toDateTime"]).format(
                "YYYY-MM-DDTHH:MM:SS"
              );
              toDate = convert_date_time_iso_format(toDate);
            }

            const floorValues = buildingStore
              .officeAccessLoadSpaces(
                visitorRegistrationFormValues["locationId"],
                event.target.value,
                fromDate,
                toDate
              )
              .then(() => {
                setSpaceArray(toJS(buildingStore?.spaces));
              });
          }
        }
        break;
      case "spaceId":
        if (formValues["bookADesk"]) {
          formValues["spaceId"] = event.target.value;
          errorText["spaceId"] = "";
          errorState["spaceId"] = false;
        }
        break;
      default:
        break;
    }
    setVisitorRegistrationFormValues(formValues);
    setVisitorRegistrationErrorValues(errorState);
    setVisitorRegistrationHelperTextValues(errorText);
  };

  const formFieldTitle = (title: string) => {
    return (
      <Grid item xs={12} sm={3}>
        <Typography
          className={classes.h4}
          style={{ display: title == "Photo Upload" ? "flex" : "" }}
        >
          {title}
          {title == "Photo Upload" && (
            <InformationIcon onclick={() => handlePhotoInformationOpen()} />
          )}
        </Typography>
      </Grid>
    );
  };

  const showSelectedDeskBlock = (e) => {
    if (localStorage.getItem("selectedSpace")) {
      setSelectedDeskContent("You selected the desk : " + localStorage.getItem("selectedSpace"));
      setShowSelectedDesk(true);
    }
  };

  const [spaceId, setSpaceId] = useState<number>(0);
  const handleSpaceIdChange = (newValue: any) => {
    setSpaceId(newValue.target.value);
  };

  const [locationId, setLocationId] = useState<number>(0);
  const [hostId, setHostId] = useState<number>(0);
  const handleHostNameIdChange = (newValue: any) => {
    setFormValues("hostNameId", newValue.id);
    setHostId(newValue.id);
  };

  const handleAccessAreaChange = (event) => {
    const thisVal = event.target.value;
    setAccessArea(thisVal);
    setFormValues("accessAreaId", thisVal);
  };

  const handlevmsAccessAreaChange = (event) => {
    const thisVal = event.target.value;
    setvmsAccessAreas(thisVal);
    setFormValues("vmsAccessArea", thisVal);
  };

  const handleCardHolderIdChange = (newValue: any) => {
    setFormValues("cardHolderId", newValue.id);
  };

  const handleInternalVisitorCardHolderIdChange = (newValue: any) => {
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      setFormValues(`cardHolderId${elementSuffix}`, newValue.id);
    });
  };

  const handleSupervisorIdChange = (newValue: any) => {
    setFormValues("supervisorId", newValue.id);
  };

  const [isOpen, setIsOpen] = useState(false);

  const clearForm = () => {
    setVisitorRegistrationFormValues(initialFormState);
    setVisitorRegistrationErrorValues(initialFormErrorState);
    setVisitorRegistrationHelperTextValues(initialFormHelperTextState);
  };

  const [photoInformationOpen, setPhotoInformationOpen] = useState(false);
  const handlePhotoInformationOpen = () => setPhotoInformationOpen(true);
  const handlePhotoInformationClose = () => setPhotoInformationOpen(false);

  const [message, setMessage] = useState<string>("");
  const [registrationButtonStatus, setRegistrationButtonStatus] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);

  const isFieldValid = (field: { fieldName: string; helperText: string; isRequired: boolean }) => {
    const value = visitorRegistrationFormValues[field.fieldName];
    if (typeof value == undefined) {
      console.log("test not found: ", field.fieldName);
      return false;
    }
    // if field exists assume ok, unless required no value
    let returnValue = true;
    if (field.isRequired) {
      if (value == 0 || value == "") returnValue = false;
    }

    // special checks
    if ([CardType["Employee / Embedded Contractor"]].includes(+cardType)) {
      if (field.fieldName.startsWith("toDate")) returnValue = false;
    }
    if (field.fieldName.startsWith("email")) {
      let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
      if (re.test(value) == false) returnValue = false;
    }
    return returnValue;
  };

  // const getBuildingTimezone = (buildingId: number) => {
  //   if (buildingId) {
  //     let building_timezone = buildingArray.filter((location) => location.id == buildingId);
  //     if (building_timezone) return building_timezone[0].building_timezone;
  //   }
  //   return getBuildingTimezone(buildingId);
  // };
  const [emailIdArray, setEmailIdArray] = useState<string[]>([]);
  const findDuplicateEmail = () => {
    let emailIdList = [...emailIdArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      emailIdList.push(visitorRegistrationFormValues[`emailId${elementSuffix}`]);
      // setEmailIdArray(emailIdList);
    });
    return emailIdList;
  };

  const [vmsStartDateValidation, setVmsStartDateValidation] = useState(false);
  const [vmsEndDateValidation, setVmsEndDateValidation] = useState(false);

  const checkForm = (cardType: CardType, formFields: FormElement[] = []) => {
    let errorText = { ...visitorRegistrationHelperTextValues };
    let errorState = { ...visitorRegistrationErrorValues };
    let isValid = true;
    let requiredFields: FormElement[] = [...formFields];

    if (requiredFields.length > 0) {
      requiredFields.forEach((field) => {
        const value = visitorRegistrationFormValues[field.fieldName];
        let testResult = isFieldValid(field);
        let helperText = field.helperText;
        if (field.fieldName.startsWith("email")) {
          // let re = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          let re = /^\w+[-+_%\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          if (value !== "" && re.test(value) == false) {
            helperText = `${helperText} - non BHP`;
            testResult = false;
          } else testResult = true;
          if ([CardType["External Visitor [pass required]"]].includes(+cardType)) {
            let duplicateEmailIds = findDuplicateEmail();
            let unique = new Set();
            for (let i = 0; i < duplicateEmailIds.length; i++) {
              if (unique.has(duplicateEmailIds[i])) {
                console.log("duplicate element", duplicateEmailIds[i]);
                testResult = false;
                helperText = "Duplicate email id's are not allowed";
              }
              unique.add(duplicateEmailIds[i]);
            }
          }
        }

        const supervisor = visitorRegistrationFormValues["supervisorId"];
        const host = visitorRegistrationFormValues["hostNameId"];
        const cardHolder = visitorRegistrationFormValues["cardHolderId"];
        if (testResult && field.fieldName.startsWith("cardHolder")) {
          // can't be same as supervisor or hostName
          if (value === supervisor || value === host) {
            testResult = false;
            helperText = `Can't be same as ${value === supervisor ? "supervisor" : "host"}`;
          }
        }
        if (testResult && field.fieldName.startsWith("supervisor")) {
          // can't be same as cardholder or hostName
          if (value === cardHolder || value === host) {
            testResult = false;
            helperText = `Can't be same as ${value === cardHolder ? "card holder" : "host"}`;
          }
        }
        if (testResult && field.fieldName.startsWith("hostName")) {
          // can't be same as cardholder or hostName
          if (value === cardHolder || value === supervisor) {
            testResult = false;
            helperText = `Can't be same as ${value === cardHolder ? "card holder" : "supervisor"}`;
          }
        }

        if (field.fieldName.startsWith("fromDateTime")) {
          let selectedFromDateTime = value;
          let todays_date = moment(new Date()).format("YYYY-MM-DD");

          if (![CardType["Employee / Embedded Contractor"]].includes(+cardType)) {
            if (moment(selectedFromDateTime).isAfter(todays_date) == false) {
              testResult = false;
              helperText = "Please select a future day";
            }
          }
          if ([CardType["External Visitor [pass required]"]].includes(+cardType)) {
            let specialAccessArea = visitorRegistrationFormValues["vmsAccessArea"];
            // let building_timezone = getBuildingTimezone(visitorRegistrationFormValues["locationId"]);
            const fromDate = moment(visitorRegistrationFormValues["fromDateTime"]);
            let currentDate = moment(new Date()).format("YYYY-MM-DD");
            let maxFromDateTime = moment(currentDate).add(1, "hours").format();
            let maxSpecialAccessDate = moment(currentDate).add(1, "hours").format();

            if (specialAccessArea) {
              let vmsArea = vmsLocationAccessAreas.filter((vms) => vms.id == specialAccessArea);
              if (vmsArea[0].vms_access_group && vmsArea[0].vms_special_access_required) {
                if (moment(selectedFromDateTime).isBefore(maxSpecialAccessDate)) {
                  helperText =
                    "IROC requests must be submitted at least 1 hour prior to the proposed start date.";
                  testResult = false;
                }
              }
            } else {
              if (fromDate.isBefore(maxFromDateTime)) {
                helperText = "Request must be submitted 1 Hour* prior to the start date time";
                testResult = false;
              }
            }
          }
        }
        if (field.fieldName.startsWith("toDateTime")) {
          let selectedFromDateTime = visitorRegistrationFormValues["fromDateTime"];
          let selectedToDateTime = value;
          if (![CardType["Employee / Embedded Contractor"], CardType["Special Access Area"]]) {
            if (moment(selectedToDateTime).isAfter(moment(selectedFromDateTime)) == false) {
              testResult = false;
              helperText = "Please select an end after the start";
            } else {
              if (!isRequestAccess) {
                let maxToDateTime = moment(selectedFromDateTime).add(7, "days").format();
                if (moment(selectedToDateTime).isAfter(maxToDateTime)) {
                  helperText = "Please select a day which makes the visit less than 7 days";
                  testResult = false;
                }
              } else {
                if (
                  [
                    CardType["External Visitor (pass required)"],
                    CardType["Child Visitor"],
                    CardType["External Visitor (no pass required)"],
                    CardType["External Visitor [pass required]"],
                  ].includes(+cardType)
                ) {
                  let maxToDateTime = moment(selectedFromDateTime).add(7, "days").format();
                  if (moment(selectedToDateTime).isAfter(maxToDateTime)) {
                    helperText = "Please select a date so the visit is less than 7 days";
                    testResult = false;
                  }
                }
                if ([CardType["Internal Visitor"]].includes(+cardType)) {
                  let maxToDateTime = moment(selectedFromDateTime).add(30, "days").format();
                  if (moment(selectedToDateTime).isAfter(maxToDateTime)) {
                    helperText = "Please select a day which makes the visit less than 30 days";
                    testResult = false;
                  }
                }
              }
            }
          } else {
            if (selectedToDateTime) {
              if (moment(selectedToDateTime).isAfter(moment(selectedFromDateTime)) == false) {
                testResult = false;
                helperText = "Please select an end date/time after the meeting start";
              }
            }
            testResult = true;
          }
        }
        if (!testResult) isValid = false;
        errorText[field.fieldName] = testResult ? undefined : helperText;
        errorState[field.fieldName] = !testResult;
        if (field.fieldName.startsWith("email")) {
          // let re = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          let re = /^\w+[-+_%\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          // loop for all visitors
          visitors.forEach((idx) => {
            if (idx > 1) {
              const thisFieldName = `emailId-${idx - 1}`;
              const thisValue = visitorRegistrationFormValues[thisFieldName];
              if (thisValue !== "" && re.test(thisValue) == false) {
                helperText = "You need to provide email address - non BHP";
                errorText[thisFieldName] = helperText;
                errorState[thisFieldName] = true;
              } else {
                testResult = true;
                errorText[thisFieldName] = "";
                errorState[thisFieldName] = false;
              }
            }
          });
        }
        if (
          field.fieldName.startsWith("hostName") &&
          [CardType["External Visitor [pass required]"]].includes(+cardType)
        ) {
          let hostName = visitorRegistrationFormValues["hostNameId"];
          helperText = validHostErrortxt;
          if (validHost) {
            testResult = true;
          } else {
            testResult = false;
            errorText["hostNameId"] = helperText;
            errorState["hostNameId"] = true;
            isValid = false;
          }
        }
        if (
          [CardType["External Visitor [pass required]"]].includes(+cardType) &&
          field.fieldName.startsWith("phoneNumber")
        ) {
          visitors.forEach((idx) => {
            if (idx > 1) {
              const thisFieldName = `under18-${idx - 1}`;
              const name = `phoneNumber-${idx - 1}`;
              const val = visitorRegistrationFormValues[name];
              const thisValue = visitorRegistrationFormValues[thisFieldName];
              if ((!thisValue && val == "") || (thisValue == undefined && val == undefined)) {
                errorText[name] = helperText;
                errorState[name] = true;
                isValid = false;
              }
            } else {
              const name = "phoneNumber";
              const thisValue = visitorRegistrationFormValues["under18"];
              const val = visitorRegistrationFormValues["phoneNumber"];
              if (!thisValue && val == "") {
                errorText[name] = helperText;
                errorState[name] = true;
                isValid = false;
              }
            }
          });
        }

        if (
          [CardType["External Visitor [pass required]"]].includes(+cardType) &&
          field.fieldName.startsWith("phoneNumber")
        ) {
          const phoneNumber = visitorRegistrationFormValues["phoneNumber"];
          const phoneNumberRegex = /^(([+]\d{2,4}\d{9}$)|(^[0]\d{9}$))$/;

          if (phoneNumber) {
            if (phoneNumber.length < 10) {
              errorText["phoneNumber"] = "Mobile number should be at least 10 digits";
              errorState["phoneNumber"] = true;
              isValid = false;
            } else if (!phoneNumberRegex.test(phoneNumber)) {
              errorText["phoneNumber"] =
                "Mobile number format is invalid. Example: +12345678901 or 0123456789";
              errorState["phoneNumber"] = true;
              isValid = false;
            } else {
              errorText["phoneNumber"] = "";
              errorState["phoneNumber"] = false;
            }
          }
        }

        if (
          [CardType["External Visitor [pass required]"]].includes(+cardType) &&
          field.fieldName.startsWith("toDateTime")
        ) {
          let selectedFromDateTime = visitorRegistrationFormValues["fromDateTime"];
          let selectedToDateTime = visitorRegistrationFormValues["toDateTime"];
          let maxToDateTime = moment(selectedFromDateTime).add(7, "days").format();
          if (moment(selectedToDateTime).isAfter(maxToDateTime)) {
            errorText["toDateTime"] = "Please select a date so the visit is less than 7 days";
            errorState["toDateTime"] = true;
            isValid = false;
          }
          if (selectedToDateTime) {
            if (moment(selectedToDateTime).isAfter(moment(selectedFromDateTime)) == false) {
              errorText["toDateTime"] = "Please select an end date/time after the meeting start";
              errorState["toDateTime"] = true;
              isValid = false;
            }
          }
        }
        if (![CardType["External Visitor [pass required]"]].includes(+cardType)) {
          ["firstName", "lastName", "companyName", "phoneNumber"].forEach((fld) => {
            if (field.fieldName.startsWith(fld)) {
              // loop for all visitors
              visitors.forEach((idx) => {
                if (idx > 1) {
                  const thisFieldName = `${fld}-${idx - 1}`;
                  const thisValue = visitorRegistrationFormValues[thisFieldName];
                  const testResult = field.isRequired
                    ? thisValue == 0 || thisValue == "" || !thisValue
                      ? false
                      : true
                    : true;
                  errorText[thisFieldName] = testResult ? undefined : helperText;
                  errorState[thisFieldName] = !testResult;
                }
              });
            }
          });
        }

        if ([CardType["External Visitor [pass required]"]].includes(+cardType)) {
          ["firstName", "lastName", "companyName"].forEach((fld) => {
            if (field.fieldName.startsWith(fld)) {
              // loop for all visitors
              visitors.forEach((idx) => {
                if (idx > 1) {
                  const thisFieldName = `${fld}-${idx - 1}`;
                  const thisValue = visitorRegistrationFormValues[thisFieldName];
                  const testResult = field.isRequired
                    ? thisValue == 0 || thisValue == "" || !thisValue
                      ? false
                      : true
                    : true;
                  errorText[thisFieldName] = testResult ? undefined : helperText;
                  errorState[thisFieldName] = !testResult;
                }
              });
            }
          });
        }

        if ([CardType["External Visitor [pass required]"]].includes(+cardType)) {
          let vmsArea = vmsLocationAccessAreas.filter(
            (vms) => vms.id == visitorRegistrationFormValues["vmsAccessArea"]
          );

          if (vmsArea[0].name === "Level 45") {
            if (field.fieldName === "fromDateTime" || field.fieldName === "toDateTime") {
              const fromDate = moment(visitorRegistrationFormValues["fromDateTime"]);
              const toDate = moment(visitorRegistrationFormValues["toDateTime"]);

              const perthHolidays = getHolidays([
                fromDate.year().toString(),
                toDate.year().toString(),
              ]).filter((holiday) => {
                const holidayDate = moment(holiday.date);
                return (
                  holidayDate.isSameOrAfter(fromDate, "day") &&
                  holidayDate.isSameOrBefore(toDate, "day") &&
                  holiday.holidays.some(
                    (h) => h.country === "Australia" && h.state === "Western Australia"
                  )
                );
              });

              // Additional validation for time range and day of the week
              const isSameDay = fromDate.isSame(toDate, "day");
              const isWeekday = fromDate.isoWeekday() >= 1 && fromDate.isoWeekday() <= 5;
              const isInMorningPeriod =
                fromDate.isBetween(
                  moment(fromDate).set({ hour: 9, minute: 29 }),
                  moment(fromDate).set({ hour: 11, minute: 31 })
                ) &&
                toDate.isBetween(
                  moment(toDate).set({ hour: 9, minute: 29 }),
                  moment(toDate).set({ hour: 11, minute: 31 })
                );
              const isInAfternoonPeriod =
                fromDate.isBetween(
                  moment(fromDate).set({ hour: 13, minute: 29 }),
                  moment(fromDate).set({ hour: 15, minute: 31 })
                ) &&
                toDate.isBetween(
                  moment(toDate).set({ hour: 13, minute: 29 }),
                  moment(toDate).set({ hour: 15, minute: 31 })
                );

              // Check if any date within the selected date range falls on a public holiday
              if (perthHolidays.length > 0) {
                //console.log("Public holidays in Perth:", perthHolidays);
                const errorMessage =
                  "Please select a date range that does not fall on a public holiday for Perth.";
                errorText["fromDateTime"] = errorMessage;
                errorText["toDateTime"] = errorMessage;
                errorState["fromDateTime"] = true;
                errorState["toDateTime"] = true;
                isValid = false;
              } else if (!isSameDay) {
                const errorMessage = "Start and End time should be within a single day.";
                errorText["fromDateTime"] = errorMessage;
                errorText["toDateTime"] = errorMessage;
                errorState["fromDateTime"] = true;
                errorState["toDateTime"] = true;
                isValid = false;
              } else if (!isWeekday) {
                const errorMessage =
                  "The selected date range must be on a weekday (Monday to Friday).";
                errorText["fromDateTime"] = errorMessage;
                errorText["toDateTime"] = errorMessage;
                errorState["fromDateTime"] = true;
                errorState["toDateTime"] = true;
                isValid = false;
              } else if (!isInMorningPeriod && !isInAfternoonPeriod) {
                const errorMessage =
                  "The selected time must be during off-peak periods: 9:30am to 11:30am or 1:30pm to 3:30pm.";
                errorText["fromDateTime"] = errorMessage;
                errorText["toDateTime"] = errorMessage;
                errorState["fromDateTime"] = true;
                errorState["toDateTime"] = true;
                isValid = false;
              }
            }
          }
        }

        if (
          [
            CardType["External Visitor (pass required)"],
            CardType["Internal Visitor"],
            CardType["Child Visitor"],
            CardType["Employee / Embedded Contractor"],
            CardType["Short-Term employee / Contractor"],
          ].includes(+cardType)
        ) {
          if (field.fieldName.startsWith("accessAreaId")) {
            if (specialAccessPass && !("accessAreaId" in visitorRegistrationFormValues)) {
              errorText["accessAreaId"] = helperText;
              errorState["accessAreaId"] = true;
              isValid = false;
            }
          }
          if (field.fieldName.startsWith("accessReason")) {
            if (specialAccessPass && !visitorRegistrationFormValues["accessReason"]) {
              errorText["accessReason"] = helperText;
              errorState["accessReason"] = true;
              isValid = false;
            }
          }
        }
        if (
          [
            CardType["Employee / Embedded Contractor"],
            CardType["Short-Term employee / Contractor"],
          ].includes(+cardType)
        ) {
          if (field.fieldName.startsWith("additionalInfo")) {
            if (!visitorRegistrationFormValues["additionalInfo"]) {
              errorText["additionalInfo"] = helperText;
              errorState["additionalInfo"] = true;
              isValid = false;
            }
          }
        }
      });
    }
    if (!isValid) console.log("fail: ", errorState);
    setVisitorRegistrationErrorValues(errorState);
    setVisitorRegistrationHelperTextValues(errorText);
    return isValid;
  };

  // VMS Integration
  const [emailArray, setEmailArray] = useState<string[]>([]);
  const [firstNameArray, setFirstNameArray] = useState<string[]>([]);
  const [lastNameArray, setLastNameArray] = useState<string[]>([]);
  const [phoneNumberArray, setPhoneNumberArray] = useState<string[]>([]);
  const [companyNameArray, setCompanyNameArray] = useState<string[]>([]);
  const [mobileCredFlagArray, setMobileCredFlagArray] = useState<boolean[]>([]);
  const [childVisitorsFlagArray, setChildVisitorsFlagArray] = useState<boolean[]>([]);
  const [vmsAccessAreas, setvmsAccessAreas] = useState<number>(0);

  const get_visitors_email = () => {
    let emailList = [...emailArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      emailList.push(visitorRegistrationFormValues[`emailId${elementSuffix}`]);
      setEmailArray(emailList);
    });
    return emailList;
  };

  const get_visitors_firstname = () => {
    let firstNameList = [...firstNameArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      firstNameList.push(visitorRegistrationFormValues[`firstName${elementSuffix}`]);
      setFirstNameArray(firstNameList);
    });
    return firstNameList;
  };
  const get_visitors_lastname = () => {
    let lastNameList = [...lastNameArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      lastNameList.push(visitorRegistrationFormValues[`lastName${elementSuffix}`]);
      setLastNameArray(lastNameList);
    });
    return lastNameList;
  };

  const get_visitors_companyname = () => {
    let companyNameList = [...companyNameArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      companyNameList.push(visitorRegistrationFormValues[`companyName${elementSuffix}`]);
      setCompanyNameArray(companyNameList);
    });
    return companyNameList;
  };

  const get_visitors_phonenumber = () => {
    let phoneNumberList = [...phoneNumberArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      if (visitorRegistrationFormValues[`phoneNumber${elementSuffix}`] == undefined) {
        phoneNumberList.push("");
      } else phoneNumberList.push(visitorRegistrationFormValues[`phoneNumber${elementSuffix}`]);
      setCompanyNameArray(phoneNumberList);
    });
    return phoneNumberList;
  };

  const get_childVisitorsFlag = () => {
    let childVisitorFlagList = [...childVisitorsFlagArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      if (visitorRegistrationFormValues[`under18${elementSuffix}`] == undefined) {
        childVisitorFlagList.push(false);
      } else childVisitorFlagList.push(visitorRegistrationFormValues[`under18${elementSuffix}`]);
      setChildVisitorsFlagArray(childVisitorFlagList);
    });
    return childVisitorFlagList;
  };

  const get_mobileCredentialsFlag = () => {
    let mobileCredFlagList = [...mobileCredFlagArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `${idx - 1}` : "";
      if (visitorRegistrationFormValues[`mobileCredentials${elementSuffix}`] == undefined) {
        mobileCredFlagList.push(true);
      } else
        mobileCredFlagList.push(visitorRegistrationFormValues[`mobileCredentials${elementSuffix}`]);
      setMobileCredFlagArray(mobileCredFlagList);
    });
    return mobileCredFlagList;
  };
  // Additional fields for VMS gallagher for external visitors
  // Mobile credentials, under18-for child visitor validation
  const initialMobileCredState = {
    mobileCredentials: true,
    mobileCredentials1: true,
  };

  const [initialMobileCredStates, setInitialMobileCredState] =
    useState<object>(initialMobileCredState);

  const [mobileCredFlag, setMobileCredFlag] = useState<object>(initialMobileCredStates);

  const getMobileCredFlag = (fieldName: string, value: boolean) => {
    let mobileCredState = { ...mobileCredFlag };
    mobileCredState[fieldName] = value;
    setMobileCredFlag(mobileCredState);
  };

  const hangleChildVisitorChange = (evt, titleSuffix) => {
    let formValues = { ...visitorRegistrationFormValues };
    const { name } = evt.target;
    const childVisitorChecked = evt.target.checked;
    visitors.forEach((idx) => {
      if (titleSuffix >= 1) {
        if (childVisitorChecked) {
          formValues[name] = childVisitorChecked;
          setVisitorRegistrationFormValues(formValues);
          getMobileCredFlag(`mobileCredentials${titleSuffix}`, !childVisitorChecked);
          formValues[`mobileCredentials${titleSuffix}`] = !childVisitorChecked;
          setVisitorRegistrationFormValues(formValues);
        } else {
          formValues[name] = childVisitorChecked;
          setVisitorRegistrationFormValues(formValues);
          getMobileCredFlag(`mobileCredentials${titleSuffix}`, !childVisitorChecked);
          formValues[`mobileCredentials${titleSuffix}`] = !childVisitorChecked;
          setVisitorRegistrationFormValues(formValues);
        }
      } else {
        if (childVisitorChecked) {
          formValues[name] = childVisitorChecked;
          getMobileCredFlag("mobileCredentials", !evt.target.checked);
          formValues["mobileCredentials"] = !childVisitorChecked;
          setVisitorRegistrationFormValues(formValues);
        } else {
          formValues[name] = childVisitorChecked;
          getMobileCredFlag("mobileCredentials", !childVisitorChecked);
          formValues["mobileCredentials"] = !childVisitorChecked;
          setVisitorRegistrationFormValues(formValues);
        }
      }
    });
  };

  const handleMobileCredentialsChange = (evt, titleSuffix) => {
    let formValues = { ...visitorRegistrationFormValues };
    // const { name } = evt.target;
    const mobileCredChecked = evt.target.checked;
    visitors.forEach((idx) => {
      if (titleSuffix >= 1) {
        if (mobileCredChecked) {
          getMobileCredFlag(`mobileCredentials${titleSuffix}`, mobileCredChecked);
          formValues[`mobileCredentials${titleSuffix}`] = mobileCredChecked;
          setVisitorRegistrationFormValues(formValues);
        } else {
          getMobileCredFlag(`mobileCredentials${titleSuffix}`, mobileCredChecked);
          formValues[`mobileCredentials${titleSuffix}`] = mobileCredChecked;
          setVisitorRegistrationFormValues(formValues);
        }
      } else {
        if (evt.target.checked) {
          getMobileCredFlag("mobileCredentials", mobileCredChecked);
          formValues["mobileCredentials"] = mobileCredChecked;
          setVisitorRegistrationFormValues(formValues);
        } else {
          getMobileCredFlag("mobileCredentials", mobileCredChecked);
          formValues["mobileCredentials"] = mobileCredChecked;
          setVisitorRegistrationFormValues(formValues);
        }
      }
    });
  };

  const convert_date_time_iso_format = (date_string) => {
    const str = date_string;
    const [dateValues, timeValues] = str.split("T");
    const [year, month, day] = dateValues.split("-");
    const [hours, minutes, seconds] = timeValues.split(":");
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    return date.toISOString();
  };

  const displayResponse = (
    response: any,
    successText: string = "Request created successfully",
    failText: string = "Not saved !!!"
  ) => {
    if (response.status == 201 || response.status == 200) {
      setMessage(successText);
      setRegistrationButtonStatus(true);
      setIsOpen(true);
      clearForm();
    } else {
      setIsOpen(true);
      setIsApiError(true);
      const responseMessage = response.data.response.data
        ? JSON.stringify(response.data.response.data)
        : failText;
      setMessage(responseMessage);
    }
  };

  const loadMwdBuildingRequests = (buildingRequestVmsId: number) => {
    visitors.map((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      const indx = idx > 1 ? `${idx - 1}` : "";
      const VmsVisitorAccessRequest: BuildingAcccessVMSExternalVisitorModel = {
        location_id: visitorRegistrationFormValues["locationId"],
        card_type_id: visitorRegistrationFormValues["cardTypeId"],
        start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
        end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
        requester_id: userStore.me!.id,
        host_name_id: visitorRegistrationFormValues["hostNameId"],
        email_id: visitorRegistrationFormValues[`emailId${elementSuffix}`],
        first_name: visitorRegistrationFormValues[`firstName${elementSuffix}`],
        last_name: visitorRegistrationFormValues[`lastName${elementSuffix}`],
        company: visitorRegistrationFormValues[`companyName${elementSuffix}`],
        phone_number: visitorRegistrationFormValues[`phoneNumber${elementSuffix}`],
        mobile_credentials:
          visitorRegistrationFormValues[`mobileCredentials${indx}`] ||
          visitorRegistrationFormValues[`mobileCredentials${indx}`] == undefined
            ? true
            : false,
        under18: visitorRegistrationFormValues[`under18${elementSuffix}`] || false,
        vms_access_area: visitorRegistrationFormValues["vmsAccessArea"],
        meeting_name: visitorRegistrationFormValues["meetingName"],
        meeting_description: visitorRegistrationFormValues["meetingDescription"],
        notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
        building_request_vms_id: buildingRequestVmsId,
      };
      bamStore.createAccessRequest_Visitor(VmsVisitorAccessRequest).then((response) => {
        if (response.status == 201 || response.status == 200) {
          console.log("Visitor has created successfully in MWD", response.status);
          // return response.status;
        }
      });
    });
  };

  const doVisitorRegistrationBooking = async () => {
    setIsSending(true);
    if (isRequestAccess) {
      switch (+cardType) {
        case CardType["Replacement Card"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            const replacementCardAccessRequest: BuildingAcccessReplacementCardModel = {
              location_id: visitorRegistrationFormValues["locationId"],
              card_type_id: visitorRegistrationFormValues["cardTypeId"],
              card_holder_id: visitorRegistrationFormValues["cardHolderId"],
              company: visitorRegistrationFormValues["companyName"],
              requester_id: userStore.me!.id,
              reason_for_replacement: visitorRegistrationFormValues["reasonForReplacement"],
            };
            bamStore
              .createAccessRequest_ReplacementCard(replacementCardAccessRequest)
              .then((response) => {
                displayResponse(response, "Replacement Card Request saved");
                setIsSending(false);
              });
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
        case CardType["Internal Visitor"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            let submissionsResults: { idx: number; result: boolean; response: any }[] = [];
            visitors.map((idx) => {
              const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
              const internalVisitorAccessRequest: BuildingAcccessInternalVisitorModel = {
                location_id: visitorRegistrationFormValues["locationId"],
                card_type_id: visitorRegistrationFormValues["cardTypeId"],
                start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
                end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
                access_hours: visitorRegistrationFormValues["accessBuildingHours"],
                requester_id: userStore.me!.id,
                access_area_ids: visitorRegistrationFormValues["accessAreaId"],
                access_reason: visitorRegistrationFormValues["accessReason"],
                host_name_id: visitorRegistrationFormValues["hostNameId"],
                special_access: specialAccessPass,
                reason_for_visit: visitorRegistrationFormValues["reasonForVisit"],
                card_holder_id: visitorRegistrationFormValues[`cardHolderId${elementSuffix}`],
                book_a_desk: visitorRegistrationFormValues["bookADesk"],
                space_id: visitorRegistrationFormValues[`spaceId${elementSuffix}`] || 0,
                notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
              };
              bamStore
                .createAccessRequest_InternalVisitor(internalVisitorAccessRequest)
                .then((response) => {
                  if (response.status == 201 || response.status == 200) {
                    console.log("response.status", response.status);
                    setMessage("Internal Visitor Access Request saved");
                    setRegistrationButtonStatus(true);
                    setIsOpen(true);
                    submissionsResults.push({ idx, result: true, response });
                  } else {
                    setIsOpen(true);
                    setIsApiError(true);
                    setMessage("Not saved!!");
                    submissionsResults.push({ idx, result: false, response });
                  }
                });
            });
            const failures = submissionsResults.filter((res) => !res.result);
            console.log("failures: ", failures);
            if (failures && failures.length == 0) {
              clearForm();
            } else {
              setIsOpen(true);
              setIsApiError(true);
              setMessage(`${failures.length} requests had issues`);
            }
            setIsSending(false);
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
        case CardType["Child Visitor"]:
        case CardType["External Visitor (pass required)"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            let parent_request_id = 0;
            let submissionsResults: { idx: number; result: boolean; response: any }[] = [];
            visitors.map((idx) => {
              const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
              const externalVisitorAccessRequest: BuildingAcccessExternalVisitorModel = {
                location_id: visitorRegistrationFormValues["locationId"],
                card_type_id: visitorRegistrationFormValues["cardTypeId"],
                start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
                end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
                access_hours: visitorRegistrationFormValues["accessBuildingHours"],
                requester_id: userStore.me!.id,
                access_area_ids: visitorRegistrationFormValues["accessAreaId"],
                access_reason: visitorRegistrationFormValues["accessReason"],
                host_name_id: visitorRegistrationFormValues["hostNameId"],
                special_access: specialAccessPass,
                reason_for_visit: visitorRegistrationFormValues["reasonForVisit"],
                email_id: visitorRegistrationFormValues[`emailId${elementSuffix}`],
                first_name: visitorRegistrationFormValues[`firstName${elementSuffix}`],
                last_name: visitorRegistrationFormValues[`lastName${elementSuffix}`],
                company: visitorRegistrationFormValues[`companyName${elementSuffix}`],
                phone_number: visitorRegistrationFormValues[`phoneNumber${elementSuffix}`],
                preferred_floor_or_meeting_room_number:
                  visitorRegistrationFormValues["preferredFloorORMeetingRoomNumber"],
                book_a_desk: visitorRegistrationFormValues["bookADesk"],
                space_id: visitorRegistrationFormValues[`spaceId${elementSuffix}`] || 0,
                notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
                //parent_request_id: parent_request_id,
              };
              bamStore
                .createAccessRequest_ExternalVisitor(externalVisitorAccessRequest)
                .then((response) => {
                  if (response.status == 201 || response.status == 200) {
                    console.log("response.status", response.status);
                    if (parent_request_id == 0) parent_request_id = response.data.visitor_id;
                    setMessage("Visitor created successfully");
                    setRegistrationButtonStatus(true);
                    setIsOpen(true);
                    submissionsResults.push({ idx, result: true, response });
                  } else {
                    setIsOpen(true);
                    setIsApiError(true);
                    setMessage("Not saved!!");
                    submissionsResults.push({ idx, result: false, response });
                  }
                });
            });
            const failures = submissionsResults.filter((res) => !res.result);
            console.log("failures: ", failures);
            if (failures && failures.length == 0) {
              clearForm();
            } else {
              setIsOpen(true);
              setIsApiError(true);
              setMessage(`${failures.length} requests had issues`);
            }
            setIsSending(false);
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
        case CardType["Short-Term employee / Contractor"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            const stEmployeeAccessRequest: BuildingAcccessShortTermEmployeeModel = {
              location_id: visitorRegistrationFormValues["locationId"],
              card_type_id: visitorRegistrationFormValues["cardTypeId"],
              start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
              end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
              access_hours: visitorRegistrationFormValues["accessBuildingHours"],
              requester_id: userStore.me!.id,
              card_holder_id: visitorRegistrationFormValues["cardHolderId"],
              access_area_ids: visitorRegistrationFormValues["accessAreaId"],
              access_reason: visitorRegistrationFormValues["accessReason"],
              host_name_id: visitorRegistrationFormValues["hostNameId"],
              special_access: specialAccessPass,
              photo: visitorRegistrationFormValues["photo"],
              additional_info: visitorRegistrationFormValues["additionalInfo"],
              company: visitorRegistrationFormValues["companyName"],
            };
            console.log("st: ", stEmployeeAccessRequest);
            bamStore
              .createAccessRequest_ShortTermEmployee(stEmployeeAccessRequest)
              .then((response) => {
                displayResponse(response, "Short Term Employee Request saved");
                setIsSending(false);
              });
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
        case CardType["Employee / Embedded Contractor"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            const employeeAccessRequest: BuildingAcccessEmployeeModel = {
              location_id: visitorRegistrationFormValues["locationId"],
              card_type_id: visitorRegistrationFormValues["cardTypeId"],
              start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
              end_date_time: getLocalDate(
                visitorRegistrationFormValues["toDateTime"] == ""
                  ? "2035-01-01"
                  : visitorRegistrationFormValues["toDateTime"]
              ),

              access_hours: visitorRegistrationFormValues["accessBuildingHours"],
              requester_id: userStore.me!.id,
              card_holder_id: visitorRegistrationFormValues["cardHolderId"],
              special_access: specialAccessPass,
              access_area_ids: visitorRegistrationFormValues["accessAreaId"],
              access_reason: visitorRegistrationFormValues["accessReason"],
              host_name_id: visitorRegistrationFormValues["hostNameId"],
              photo: visitorRegistrationFormValues["photo"],
              additional_info: visitorRegistrationFormValues["additionalInfo"],
              company: visitorRegistrationFormValues["companyName"],
            };
            bamStore.createAccessRequest_Employee(employeeAccessRequest).then((response) => {
              displayResponse(response, "Exployee/Contractor Access Request saved");
              setIsSending(false);
            });
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
        case CardType["Special Access Area"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            const specialAccessRequest: BuildingAcccessSpecialAccessModel = {
              location_id: visitorRegistrationFormValues["locationId"],
              card_type_id: visitorRegistrationFormValues["cardTypeId"],
              start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
              //end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
              end_date_time: getLocalDate(
                visitorRegistrationFormValues["toDateTime"] == ""
                  ? "2035-01-01"
                  : visitorRegistrationFormValues["toDateTime"]
              ),
              access_hours: visitorRegistrationFormValues["accessBuildingHours"],
              requester_id: userStore.me!.id,
              card_holder_id: visitorRegistrationFormValues["cardHolderId"],
              supervisor_id: visitorRegistrationFormValues["supervisorId"],
              access_area_ids: visitorRegistrationFormValues["accessAreaId"],
              access_reason: visitorRegistrationFormValues["accessReason"],
              special_access: true,
            };
            bamStore.createAccessRequest_SpecialAccess(specialAccessRequest).then((response) => {
              displayResponse(response, "Special Access Request saved");
              setIsSending(false);
            });
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
        case CardType["External Visitor (no pass required)"]:
          const formCheckResult = checkForm(cardType, visitorRegistrationRequiredFormFields);
          if (formCheckResult) {
            // if (specialAccessPass && !("accessAreaId" in visitorRegistrationFormValues)) {
            //   let errorText = { ...visitorRegistrationHelperTextValues };
            //   let errorState = { ...visitorRegistrationErrorValues };
            //   errorState["accessAreaId"] = false;
            //   errorText["accessAreaId"] = "Please select access areas";
            //   setVisitorRegistrationErrorValues(errorState);
            //   setVisitorRegistrationHelperTextValues(errorText);
            //   setIsSending(false);
            //   return;
            // }
            if (
              (visitorRegistrationFormValues["bookADesk"] &&
                typeof visitorRegistrationErrorValues["floorId"] !== "undefined" &&
                !visitorRegistrationErrorValues["floorId"] &&
                typeof visitorRegistrationErrorValues["spaceId"] !== "undefined" &&
                !visitorRegistrationErrorValues["spaceId"]) ||
              !visitorRegistrationFormValues["bookADesk"]
            ) {
              let returning_visitor = displayVisitorDetailField ? false : true;
              if (returning_visitor) {
                //TODO what is this all about?
                // visitorRegistrationFormValues["firstName"] = "-";
                // visitorRegistrationFormValues["lastName"] = "-";
              }

              let parent_visitor_id = 0;
              let submissionsResults: { idx: number; result: boolean; response: any }[] = [];
              visitors.map((idx) => {
                const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
                const visitorInformation = {
                  email_id: visitorRegistrationFormValues[`emailId${elementSuffix}`],
                  first_name: visitorRegistrationFormValues[`firstName${elementSuffix}`],
                  last_name: visitorRegistrationFormValues[`lastName${elementSuffix}`],
                  company: visitorRegistrationFormValues[`companyName${elementSuffix}`] || "",
                  phone_number: visitorRegistrationFormValues[`phoneNumber${elementSuffix}`] || "",
                  space_id: visitorRegistrationFormValues[`spaceId${elementSuffix}`] || 0,
                  location_id: visitorRegistrationFormValues["locationId"],
                  preferred_floor_or_meeting_room_number:
                    visitorRegistrationFormValues["preferredFloorORMeetingRoomNumber"],
                  reason_for_visit: visitorRegistrationFormValues["reasonForVisit"],
                  from_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
                  to_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
                  // access_area: visitorRegistrationFormValues["accessArea"],
                  access_area_ids: specialAccessPass
                    ? visitorRegistrationFormValues["accessAreaId"]
                    : [],
                  host_name_id: visitorRegistrationFormValues["hostNameId"],
                  book_a_desk: visitorRegistrationFormValues["bookADesk"],
                  notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
                  requester_id: userStore.me?.id || 1,
                  returning_visitor: returning_visitor,
                  parent_visitor_id: parent_visitor_id,
                  access_pass_needed: requireAccessPass,
                  special_access: specialAccessPass,
                  access_reason: visitorRegistrationFormValues["accessReason"],
                };

                if (requireAccessPass) {
                  delete visitorInformation["access_area"];
                }

                visitorStore.createVisitor(visitorInformation).then((response) => {
                  if (response.status == 201 || response.status == 200) {
                    if (parent_visitor_id == 0) parent_visitor_id = response.data.visitor_id;
                    setMessage("Visitor created successfully");
                    setRegistrationButtonStatus(true);
                    setIsOpen(true);
                    submissionsResults.push({ idx, result: true, response });
                  } else {
                    setIsOpen(true);
                    setIsApiError(true);
                    setMessage("Not saved!!");
                    submissionsResults.push({ idx, result: false, response });
                  }
                });
              });
              const failures = submissionsResults.filter((res) => !res.result);
              console.log("failures: ", failures);
              if (failures && failures.length == 0) {
                setIsSending(false);
                clearForm();
              } else {
                setIsOpen(true);
                setIsApiError(true);
                setMessage(`${failures.length} requests had issues`);
                setIsSending(false);
              }
            }
          } else {
            setIsSending(false);
          }
          break;
        // VMS Gallagher external visitor form
        case CardType["External Visitor [pass required]"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            let parent_request_id = 0;
            let submissionsResults: { result: boolean; response: any }[] = [];
            let buildingRequestVmsId = 0;
            const VisitorAccessRequest: GallagherVMSIntergationModel = {
              location_id: visitorRegistrationFormValues["locationId"],
              card_type_id: visitorRegistrationFormValues["cardTypeId"],
              start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
              end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
              requester_id: userStore.me!.id,
              host_name_id: visitorRegistrationFormValues["hostNameId"],
              email_id: get_visitors_email(),
              first_name: get_visitors_firstname(),
              last_name: get_visitors_lastname(),
              company: get_visitors_companyname(),
              phone_number: get_visitors_phonenumber(),
              vms_access_area: visitorRegistrationFormValues["vmsAccessArea"],
              card_holder_mobile_cred: get_mobileCredentialsFlag(),
              under18: get_childVisitorsFlag(),
              meeting_name: visitorRegistrationFormValues["meetingName"],
              meeting_description: visitorRegistrationFormValues["meetingDescription"],
              notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
              meeting_room: visitorRegistrationFormValues["meetingRoom"],
            };

            bamStore
              .createAccessRequest_VMS_External_Visitor(VisitorAccessRequest)
              .then((response) => {
                if (response.data.building_request_vms_id) {
                  buildingRequestVmsId = response.data.building_request_vms_id;
                  loadMwdBuildingRequests(buildingRequestVmsId);
                }
                if (response.status == 201 || response.status == 200) {
                  console.log("response.status", response.status);
                  if (parent_request_id == 0) {
                    parent_request_id = response.data.visitor_id;
                  }
                  setMessage("Visitor created successfully");
                  setRegistrationButtonStatus(true);
                  setIsOpen(true);
                  submissionsResults.push({ result: true, response });
                } else {
                  setIsOpen(true);
                  setIsApiError(true);
                  setMessage("Not saved!!");
                  submissionsResults.push({ result: false, response });
                }
              });
            const failures = submissionsResults.filter((res) => !res.result);
            console.log("failures: ", failures);
            if (failures && failures.length == 0) {
              clearForm();
            } else {
              setIsOpen(true);
              setIsApiError(true);
              setMessage(`${failures.length} requests had issues`);
            }
            setIsSending(false);
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
      }
    }
  };

  const [isSending, setIsSending] = useState(false);

  const [teamLocation, setTeamLocation] = useState<number | string>("");
  const handleLocationChange = (newLocation: string) => {
    setTeamLocation(newLocation);
  };

  const [hostNameDropdownOptions, setHostNameDropdownOptions] = useState<PeopleName[]>([]);
  const handleHostNameFilter = (value: string) => {
    (async () => {
      if (value && value.length > 2) {
        const res = await peopleStore.getFilteredUsersByName(value);
        setHostNameDropdownOptions(res);
      } else {
        setHostNameDropdownOptions([]);
      }
    })();
  };
  const hostNameFilterHandler = useCallback(
    debounce((value: string) => handleHostNameFilter(value), 1000),
    []
  );

  const fetchFilteredVisitorsByEmail = useCallback(
    debounce(async (email) => {
      if (email && email.length > 3) {
        try {
          const response = await peopleStore.getFilteredVisitorsByName(email);
          setEmailOptions(response);
        } catch (error) {
          console.error("Error fetching visitor details:", error);
        }
      } else {
        setEmailOptions([]);
      }
    }, 500),
    []
  );

  // const handleAddTestEmail = () => {
  //   console.log("Form Values", { ...visitorRegistrationFormValues });
  //   console.log(get_visitors_email(), visitors)
  // };

  const handleEmailChange = (event, value, titleSuffix) => {
    let formValues = { ...visitorRegistrationFormValues };

    if (titleSuffix >= 1) {
      formValues[`emailId-${titleSuffix}`] = event.target.value;
    } else {
      formValues["emailId"] = event.target.value;
    }

    setVisitorRegistrationFormValues(formValues);

    if (value) {
      fetchFilteredVisitorsByEmail(value);
    }
  };

  const handleEmailSelect = (event, value, titleSuffix) => {
    if (value) {
      let formValues = { ...visitorRegistrationFormValues };

      if (titleSuffix >= 1) {
        formValues[`firstName-${titleSuffix}`] = value.first_name;
        formValues[`lastName-${titleSuffix}`] = value.last_name;
        formValues[`emailId-${titleSuffix}`] = value.email;
        formValues[`companyName-${titleSuffix}`] = value.company;
        formValues[`phoneNumber-${titleSuffix}`] = value.phone_number;

        console.log("For titleSuffix", titleSuffix, "Form values", formValues);
      } else {
        formValues["firstName"] = value.first_name;
        formValues["lastName"] = value.last_name;
        formValues["emailId"] = value.email;
        formValues["companyName"] = value.company;
        formValues["phoneNumber"] = value.phone_number;
      }

      setVisitorRegistrationFormValues(formValues);
    }
  };

  const [cardHolderDropdownOptions, setCardHolderDropdownOptions] = useState<PeopleName[]>([]);
  const handleCardHolderFilter = (value: string) => {
    return (async () => {
      if (value && value.length > 2) {
        const res = await peopleStore.getFilteredUsersByName(value);
        setCardHolderDropdownOptions(res);
      } else {
        setCardHolderDropdownOptions([]);
      }
    })();
  };

  const cardHolderFilterHandler = useCallback(
    debounce((value: string) => handleCardHolderFilter(value), 1000),
    []
  );

  const [supervisorDropdownOptions, setSupervisorDropdownOptions] = useState<PeopleName[]>([]);
  const handleSupervisorFilter = (value: string) => {
    (async () => {
      if (value && value.length > 2) {
        const res = await peopleStore.getFilteredUsersByName(value);
        setSupervisorDropdownOptions(res);
      } else {
        setSupervisorDropdownOptions([]);
      }
    })();
  };

  const supervisorFilterHandler = useCallback(
    debounce((value: string) => handleSupervisorFilter(value), 1000),
    []
  );

  // const handleClose = () => { };

  const [buildingArray, setBuildingArray] = useState<any>([]);
  const [form_prerequisites, setForm_Prerequisites] = useState<Form_prequisite[]>([]);
  const [visitors, setVisitors] = useState<number[]>([1]);
  const [visitorLimit, setVisitorLimit] = useState(false);

  const getInitalMobileCredFlag = (visitorsCount) => {
    let mobileCredChecked = { ...mobileCredFlag };
    const indx = visitorsCount;
    mobileCredFlag[`mobileCredentials${indx}`] = true;
  };

  const addVisitor = () => {
    const newVisitors = [...visitors];
    newVisitors.push(visitors.length + 1);

    if (newVisitors.length < 21) {
      setVisitors(newVisitors);
      getInitalMobileCredFlag(visitors.length + 1);
    } else {
      console.log("More than 20 visitors can be added in a single request!");
      setVisitorLimit(true);
    }
    // setVisitors(newVisitors);
    // getInitalMobileCredFlag(visitors.length + 1);
  };
  const removeVisitor = (idx: number) => {
    const newVisitors = visitors.slice(0, -1);
    setVisitors(newVisitors);
  };

  const makeAccessAreaRequired = (status: boolean, numberOfVisitors: number = 1) => {
    let requiredFields = { ...visitorRegistrationRequiredFormFields };
    // go through formFields and set accessArea fields
    if (requiredFields) {
      requiredFields.map((fld) => {
        if (fld.fieldName.startsWith("accessAreaId")) fld.isRequired = status;
        if (fld.fieldName.startsWith("accessReason")) fld.isRequired = status;
      });
      setVisitorRegistrationRequiredFields(requiredFields);
    }
  };

  const makeCompanyRequired = (status: boolean, numberOfVisitors: number = 1) => {
    let requiredFields = { ...visitorRegistrationRequiredFormFields };
    console.log("rf: ", requiredFields);
    // go through formFields and make company and phoneRequired
    if (requiredFields) {
      requiredFields.map((fld) => {
        if (fld.fieldName.startsWith("email")) fld.isRequired = status;
        if (fld.fieldName.startsWith("firstName")) fld.isRequired = status;
        if (fld.fieldName.startsWith("lastName")) fld.isRequired = status;
        if (fld.fieldName.startsWith("company")) fld.isRequired = status;
        if (fld.fieldName.startsWith("phone")) fld.isRequired = status;
      });
      setVisitorRegistrationRequiredFields(requiredFields);
    }
  };

  const [specialAccessPass, setSpecialAccessPass] = useState(false);
  const changeSpecialAccessType = (evt) => {
    setSpecialAccessPass(evt.nativeEvent.target.checked);
    makeCompanyRequired(evt.nativeEvent.target.checked);
    makeAccessAreaRequired(evt.nativeEvent.target.checked);
  };
  const [requireAccessPass, setRequireAccessPass] = useState(false);
  const changeAccessType = (evt) => {
    if (evt.nativeEvent.target.checked) {
      setAccessPassCheckboxOn(true);
    } else {
      setAccessPassCheckboxOn(false);
    }

    setRequireAccessPass(evt.nativeEvent.target.checked);
  };
  const [pass24Hours, setPass24Hours] = useState(true);
  const change24HoursPass = (yesNo: boolean) => {
    setPass24Hours(yesNo);
  };

  const drawSimpleTextField = (
    fieldName: string,
    fieldTitle: string,
    isRequired: boolean,
    placeholder: string
  ) => {
    return (
      <Grid container alignItems="center">
        {formFieldTitle(`${fieldTitle}${isRequired ? "*" : ""}`)}
        <Grid item xs={12} sm={8}>
          <TextField
            key={`t${fieldName}`}
            id={`f${fieldName}`}
            placeholder={t(placeholder)}
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            className={classes.textField}
            onChange={handleFieldChange}
            value={visitorRegistrationFormValues[fieldName]}
            name={`${fieldName}`}
            error={visitorRegistrationErrorValues[fieldName]}
            helperText={visitorRegistrationHelperTextValues[fieldName]}
          />
        </Grid>
      </Grid>
    );
  };

  const drawNameAutoSelectBox = (
    fieldName: string,
    fieldTitle: string,
    isRequired: boolean,
    dropDownOptions: any[],
    handleOnChange: any,
    filterHandler: any
  ) => {
    return (
      <Grid container alignItems="center">
        {formFieldTitle(`${fieldTitle}${isRequired ? "*" : ""}`)}
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            flexBasis: smAndUp ? `calc(67% - 155px)` : `calc(100% - 10px)`,
            maxWidth: "100%",
            flexGrow: 0,
          }}
        >
          <Autocomplete
            id={`${fieldName}AutoComplete`}
            options={[...dropDownOptions]}
            getOptionLabel={(option) => option.preferred_name + " | " + option.email || ""}
            fullWidth
            autoComplete
            defaultValue={undefined}
            clearOnBlur={false}
            className={peopleClasses.searchTextField}
            onChange={(event, value) => {
              handleOnChange(value);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                id={fieldName}
                placeholder={`${t("Search")}...`}
                size="small"
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  filterHandler(event.target.value);
                }}
                name={`${fieldName}Id`}
                error={visitorRegistrationErrorValues[fieldName]}
                helperText={visitorRegistrationHelperTextValues[fieldName]}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  const drawCardHolderIdAutoSelectBox = (
    fieldName: string,
    fieldTitle: string,
    isRequired: boolean,
    dropDownOptions: any[],
    handleOnChange: any,
    filterHandler: any
  ) => {
    return (
      <Grid container alignItems="center">
        {formFieldTitle(`${fieldTitle}${isRequired ? "*" : ""}`)}
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            flexBasis: smAndUp ? `calc(67% - 155px)` : `calc(100% - 10px)`,
            maxWidth: "100%",
            flexGrow: 0,
          }}
        >
          <Autocomplete
            id={`${fieldName}AutoComplete`}
            options={[...dropDownOptions]}
            getOptionLabel={(option) => option.preferred_name + " | " + option.email || ""}
            fullWidth
            autoComplete
            defaultValue={undefined}
            clearOnBlur={false}
            className={peopleClasses.searchTextField}
            onChange={(event, value) => {
              handleOnChange(value);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                id={fieldName}
                placeholder={`${t("Search")}...`}
                size="small"
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  filterHandler(event.target.value);
                }}
                name={`${fieldName}Id`}
                error={visitorRegistrationErrorValues[fieldName]}
                helperText={visitorRegistrationHelperTextValues[fieldName]}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  const drawAccessArea = () => {
    return (
      <Grid container alignItems="center">
        {formFieldTitle(`Access Area${getRequiredFlag("accessArea")}`)}
        <Grid item xs={12} sm={8}>
          <Select
            disableUnderline
            style={{
              border: `2px solid ${bhpColor.grey4}`,
              borderRadius: "4px",
              paddingLeft: "12px",
            }}
            className={classes.selectBox}
            id="accessArea"
            multiple
            value={accessArea}
            input={<Input />}
            onChange={(e) => handleAccessAreaChange(e)}
            MenuProps={MenuProps}
            error={visitorRegistrationErrorValues["accessAreaId"]}
          >
            {locationAccessAreas &&
              locationAccessAreas.map((name) => (
                <MenuItem key={name.id} value={name.id} style={getStyles(name, accessArea, theme)}>
                  {name.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText style={{ color: "#f4433" }}>
            {visitorRegistrationHelperTextValues["accessAreaId"]}
          </FormHelperText>
        </Grid>
      </Grid>
    );
  };

  const drawAccessReason = (isRequired: boolean) => {
    return (
      <Grid container>
        <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
          {formFieldTitle(`Access Reason${isRequired ? "*" : ""}`)}
          {/* `Reason for replacement${getRequiredFlag("reasonForReplacement")}` */}
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            key="tAccessReason"
            id="accessReason"
            multiline
            minRows={4}
            placeholder={`${t(
              "Provide detailed reason for secure and restricted areas to ensure approval. "
            )}${charLimit} ${t("character limit")}`}
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: charLimit }}
            className={classes.textField}
            onChange={handleFieldChange}
            value={visitorRegistrationFormValues["accessReason"] || ""}
            name="accessReason"
            error={visitorRegistrationErrorValues["accessReason"]}
            helperText={visitorRegistrationHelperTextValues["accessReason"]}
          />
          <Box
            style={{
              maxWidth: smAndUp ? "calc(100% - 150px)" : `calc(100% - 10px)`,
              display: "flex",
              justifyContent: "end",
              fontSize: "smaller",
              color: bhpColor.blueGrey3,
            }}
          >
            {`${charLimit - visitorRegistrationFormValues["accessReason"].length} ${t("left")}`}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, multiSelect, theme) {
    return {
      fontWeight:
        multiSelect.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const [accessArea, setAccessArea] = useState<string[]>([]);
  const [vmsAccessArea, setVmsAccessArea] = useState<string[]>([]);
  const [locationAccessAreas, setLocationAccessAreas] = useState<Special_Access_Area[]>([]);
  const [vmsLocationAccessAreas, setVMSLocationAccessAreas] = useState<Special_Access_Area[]>([]);

  const getRequiredFlag = (fieldName: string) => {
    return isRequired(fieldName) ? "*" : "";
  };
  const isRequired = (fieldName: string) => {
    if (!visitorRegistrationRequiredFormFields) return false;
    const matchingFields = visitorRegistrationRequiredFormFields.filter(
      (field) => field.fieldName === fieldName && field.isRequired
    );
    return matchingFields && matchingFields.length > 0;
  };

  const drawVisitor = (idx: number) => {
    const titleSuffix = idx > 1 ? ` ${idx - 1}` : "";
    const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
    return (
      <Fragment>
        <Grid container alignItems="center">
          {idx > 1 && idx == visitors.length && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: smAndUp ? "-25px" : "0",
                marginRight: smAndUp ? "0" : "10px",
              }}
            >
              <Tooltip title="exclude this visitor">
                <Box
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: bhpColor.blueGrey1,
                  }}
                  onClick={() => removeVisitor(idx)}
                >
                  {!smAndUp && `Remove visitor`} <IndeterminateCheckBoxOutlinedIcon />
                </Box>
              </Tooltip>
            </Grid>
          )}

          {formFieldTitle(`Email${titleSuffix}${getRequiredFlag(`emailId`)}`)}
          <Grid item xs={12} sm={8}>
            <TextField
              key={`tEmail${elementSuffix}`}
              id={`emailId${elementSuffix}`}
              placeholder={t("Enter an email (required)")}
              type="email"
              size="small"
              variant="outlined"
              fullWidth
              className={classes.textField}
              onChange={handleFieldChange}
              onBlur={checkEmailId}
              value={visitorRegistrationFormValues[`emailId${elementSuffix}`] || ""}
              name={`emailId${elementSuffix}`}
              error={visitorRegistrationErrorValues[`emailId${elementSuffix}`]}
              helperText={visitorRegistrationHelperTextValues[`emailId${elementSuffix}`]}
            />
          </Grid>
        </Grid>
        {drawSimpleTextField(
          `firstName${elementSuffix}`,
          `First Name${titleSuffix}`,
          isRequired(`firstName`),
          "Enter a first name (required)"
        )}
        {drawSimpleTextField(
          `lastName${elementSuffix}`,
          `Last Name${titleSuffix}`,
          isRequired(`lastName`),
          "Enter a last name (required)"
        )}
        {drawSimpleTextField(
          `companyName${elementSuffix}`,
          `Company${titleSuffix}`,
          isRequired(`companyName`),
          "Enter a company name"
        )}
        {drawSimpleTextField(
          `phoneNumber${elementSuffix}`,
          `Phone Number${titleSuffix}`,
          isRequired(`phoneNumber`),
          "Enter a phone number"
        )}
        {showFloorAndLocation && (
          <Grid container alignItems="center">
            {formFieldTitle("Floor*")}
            <Grid item xs={12} sm={8}>
              <SettingsSelectBox
                t={t}
                cssClass={classes.selectBox}
                phText={"--Select Floor--"}
                phValue={null}
                theItems={floorArray && floorArray.slice().sort((a, b) => (a.id < b.id ? -1 : 1))}
                valueField={"id"}
                handleChange={handleFieldChange}
                labelField={"name"}
                defaultValue={teamLocation}
                theId={"floor"}
                theKey={"floorKey"}
                name={`floorId${elementSuffix}`}
                error={visitorRegistrationErrorValues[`floorId${elementSuffix}`]}
                helperText={visitorRegistrationHelperTextValues["floorId"]}
              />
            </Grid>
          </Grid>
        )}
        {showFloorAndLocation && (
          <Grid container alignItems="center">
            {formFieldTitle("Available Desk(s)*")}
            <Grid item xs={12} sm={8}>
              <SettingsSelectBox
                t={t}
                cssClass={classes.selectBox}
                phText={"--Select Desk--"}
                phValue={null}
                theItems={
                  spaceArray && spaceArray.slice().sort((a, b) => (a.city < b.city ? -1 : 1))
                }
                labelField={"name"}
                valueField={"id"}
                handleChange={handleFieldChange}
                defaultValue={teamLocation}
                theId={"space"}
                theKey={"spaceKey"}
                name={`spaceId${elementSuffix}`}
                error={visitorRegistrationErrorValues[`spaceId${elementSuffix}`]}
                helperText={visitorRegistrationHelperTextValues["spaceId"]}
              />
            </Grid>
          </Grid>
        )}
        {showSelectedDesk && (
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={8}>
              &nbsp; &nbsp;{selectedDeskContent}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  };

  // Add or remove multiple visitor funcationality for VMS gallagher for external visitors
  const drawVmsExternalVisitor = (idx: number) => {
    const titleSuffix = idx > 1 ? ` ${idx - 1}` : "";
    const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
    const indx = idx > 1 ? `${idx - 1}` : "";
    return (
      <Fragment>
        <Grid container alignItems="center">
          {idx > 1 && idx == visitors.length && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: smAndUp ? "-25px" : "0",
                marginRight: smAndUp ? "0" : "10px",
              }}
            >
              <Tooltip title="exclude this visitor">
                <Box
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: bhpColor.blueGrey1,
                  }}
                  onClick={() => removeVisitor(idx)}
                >
                  {!smAndUp && `Remove visitor`} <IndeterminateCheckBoxOutlinedIcon />
                </Box>
              </Tooltip>
            </Grid>
          )}
          {formFieldTitle(`Email${titleSuffix}${getRequiredFlag(`emailId`)}`)}
          <Grid item xs={12} sm={8}>
            <Autocomplete
              options={emailOptions}
              getOptionLabel={(option: User) => option.email}
              freeSolo
              onInputChange={(event, value) => handleEmailChange(event, value, indx)}
              onChange={(event, value) => handleEmailSelect(event, value, indx)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={`tEmail${elementSuffix}`}
                  id={`emailId${elementSuffix}`}
                  disabled={disableUpdateFields}
                  placeholder={t("Enter an email (required)")}
                  type="email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  name={`emailId${elementSuffix}`}
                  error={visitorRegistrationErrorValues[`emailId${elementSuffix}`]}
                  helperText={visitorRegistrationHelperTextValues[`emailId${elementSuffix}`]}
                />
              )}
            />
          </Grid>
          {/* <Button onClick={handleAddTestEmail}>Test form state</Button> */}
        </Grid>
        {drawSimpleTextField(
          `firstName${elementSuffix}`,
          `First Name${titleSuffix}`,
          isRequired(`firstName`),
          "Enter a first name (required)"
        )}
        {drawSimpleTextField(
          `lastName${elementSuffix}`,
          `Last Name${titleSuffix}`,
          isRequired(`lastName`),
          "Enter a last name (required)"
        )}
        {drawSimpleTextField(
          `companyName${elementSuffix}`,
          `Company${titleSuffix}`,
          isRequired(`companyName`),
          "Enter a company name"
        )}
        {drawSimpleTextField(
          `phoneNumber${elementSuffix}`,
          `Mobile Number${titleSuffix}`,
          isRequired(`phoneNumber`),
          "Enter a phone number"
        )}
        <Grid container alignItems="center">
          {formFieldTitle(`Mobile Access${titleSuffix}${getRequiredFlag(`mobileCredentials`)}`)}
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              alignItems: "center",
              color: bhpColor.blueGrey1,
              fontStyle: "italic",
            }}
          >
            <Checkbox
              style={{ paddingLeft: 0 }}
              key={`mobileCredentials${elementSuffix}`}
              id={`mobileCredentials${elementSuffix}`}
              name={`mobileCredentials${elementSuffix}`}
              checked={mobileCredFlag[`mobileCredentials${indx}`]}
              onClick={(evt) => handleMobileCredentialsChange(evt, indx)}
            />{" "}
            Check this box to request Mobile Access
          </Grid>
          {formFieldTitle(`Visitor is under 18?${titleSuffix}${getRequiredFlag(`under18`)}`)}
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              alignItems: "center",
              color: bhpColor.blueGrey1,
              fontStyle: "italic",
            }}
          >
            <Checkbox
              style={{ paddingLeft: 0 }}
              key={`under18${elementSuffix}`}
              id={`under18${elementSuffix}`}
              name={`under18${elementSuffix}`}
              onClick={(evt) => hangleChildVisitorChange(evt, indx)}
            />{" "}
            Check this box if the visitor is under 18
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  const drawInternalVisitor = (idx: number) => {
    const titleSuffix = idx > 1 ? ` ${idx - 1}` : "";
    const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
    return (
      <Fragment>
        <Grid container alignItems="center">
          {idx > 1 && idx == visitors.length && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: smAndUp ? "-25px" : "0",
                marginRight: smAndUp ? "0" : "10px",
              }}
            >
              <Tooltip title="exclude this visitor">
                <Box
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: bhpColor.blueGrey1,
                    // marginBottom: smAndUp ? "-40px" : "0",
                  }}
                  onClick={() => removeVisitor(idx)}
                >
                  {!smAndUp && `Remove visitor`} <IndeterminateCheckBoxOutlinedIcon />
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        {drawCardHolderIdAutoSelectBox(
          `cardHolderId${elementSuffix}`,
          `Card Holder${titleSuffix}`,
          true,
          cardHolderDropdownOptions,
          handleInternalVisitorCardHolderIdChange,
          cardHolderFilterHandler
        )}
        {showFloorAndLocation && (
          <Grid container alignItems="center">
            {formFieldTitle("Floor*")}
            <Grid item xs={12} sm={8}>
              <SettingsSelectBox
                t={t}
                cssClass={classes.selectBox}
                phText={"--Select Floor--"}
                phValue={null}
                theItems={floorArray && floorArray.slice().sort((a, b) => (a.id < b.id ? -1 : 1))}
                valueField={"id"}
                handleChange={handleFieldChange}
                labelField={"name"}
                defaultValue={teamLocation}
                theId={"floor"}
                theKey={"floorKey"}
                name={`floorId${elementSuffix}`}
                error={visitorRegistrationErrorValues[`floorId${elementSuffix}`]}
                helperText={visitorRegistrationHelperTextValues["floorId"]}
              />
            </Grid>
          </Grid>
        )}
        {showFloorAndLocation && (
          <Grid container alignItems="center">
            {formFieldTitle("Available Desk(s)*")}
            <Grid item xs={12} sm={8}>
              <SettingsSelectBox
                t={t}
                cssClass={classes.selectBox}
                phText={"--Select Desk--"}
                phValue={null}
                theItems={
                  spaceArray && spaceArray.slice().sort((a, b) => (a.city < b.city ? -1 : 1))
                }
                labelField={"name"}
                valueField={"id"}
                handleChange={handleFieldChange}
                defaultValue={teamLocation}
                theId={"space"}
                theKey={"spaceKey"}
                name={`spaceId${elementSuffix}`}
                error={visitorRegistrationErrorValues[`spaceId${elementSuffix}`]}
                helperText={visitorRegistrationHelperTextValues["spaceId"]}
              />
            </Grid>
          </Grid>
        )}
        {showSelectedDesk && (
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={8}>
              &nbsp; &nbsp;{selectedDeskContent}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  };

  const copyAsEndDateIfEndEmpty = () => {
    let formValues = { ...visitorRegistrationFormValues };
    const fromDate = formValues["fromDateTime"];
    const toDate = formValues["toDateTime"];
    formValues["toDateTime"] = fromDate;
    setVisitorRegistrationFormValues(formValues);
  };

  const [cardType, setCardType] = useState<CardType>(CardType.None);
  const [cardTypesForLocation, setCardTypesForLocation] = useState<Form_prequisite[]>([]);

  const getCardTypeBlurb = (typ: CardType, locationId: number | null) => {
    if (!cardTypesForLocation) return getDefaultCardTypeBlurb(typ);
    const theCard = cardTypesForLocation.filter((crd) => crd.card_type == +typ);
    if (theCard[0]) return theCard[0].prerequisites_data;
    return getDefaultCardTypeBlurb(typ);
  };

  const [showMainForm, setShowMainForm] = useState(false);
  const [visitorRegistrationRequiredFormFields, setVisitorRegistrationRequiredFields] = useState<
    FormElement[]
  >(registrationFormFields["visitor"]);

  const setRequiredFormFields = (cardType: CardType) => {
    setVisitorRegistrationRequiredFields(registrationFormFields[CardType[cardType]]);
  };

  const maxPhotoSize = 3; //Mb
  const sizeOk = (photo: any) => {
    return photo.size / 1024 / 1024 < maxPhotoSize;
  };
  const [photo, setPhoto] = useState<any>(null);
  const handleImageChange = (evt) => {
    const thePhoto = evt.target.files ? evt.target.files[0] : null;
    if (thePhoto) {
      const size = thePhoto.size;
      const type = thePhoto.type;
      const name = thePhoto.name;
      setPhoto(thePhoto);
      setFormValues("photo", thePhoto);
    }
  };

  useEffect(() => {
    visitorRegistrationFormValues["accessBuildingHours"] = pass24Hours ? 1 : 2;
  }, [pass24Hours]);

  const [validHost, setValidHost] = useState(false);
  const [validHostErrortxt, setvalidHostErrortxt] = useState(
    "Please select some other Host since the mentioned the Host profile is unavailable in Gallagher."
  );
  useEffect(() => {
    const hostName = hostId;
    const locationId = visitorRegistrationFormValues["locationId"];
    if (
      hostId != 0 &&
      locationId == 1 &&
      [CardType["External Visitor [pass required]"]].includes(+cardType)
    ) {
      bamStore.get_vmsCardHolder(hostName).then((response) => {
        if (response.status === 201 || response.status === 200) {
          //console.log("Valid Host.. response.status", response.status);
          setValidHost(true);
        } else if (response.status === 400 || response.status === 404) {
          //console.log("Invalid Host.. response.status", response.status);
          setvalidHostErrortxt(
            "Please select some other Host since the mentioned the Host profile is unavailable in Gallagher."
          );
          setValidHost(false);
        } else if (response.status === 500 || response.status === 503) {
          setvalidHostErrortxt(
            "Gallagher API is down. Please try later or check with the receptionist for immediate visit creation requests."
          );
          setValidHost(false);
        }
      });
    }
  }, [hostId]);

  useEffect(() => {
    const hasLocation = !["", "0"].includes(`${visitorRegistrationFormValues["locationId"]}`);
    const showForm = isRequestAccess && cardType !== CardType.None && hasLocation;
    if (isRequestAccess) {
      buildingStore.loadBuildingsWithCapacity(moment(new Date()).format("YYYY-MM-DD")).then(() => {
        setBuildingArray(
          buildingStore.buildingsWithCapacity
            .filter((bld) => SV_BuildingsIDsToInclude.includes(bld.serraview_id || 0))
            .sort((a, b) => (a.city < b.city ? -1 : 1))
            .map((bld) => {
              const id = bld.id;
              const display_name = `${bld.city}, ${bld.building_name}`;
              return { id, display_name };
            })
        );
      });
      setShowMainForm(showForm);
      setRequiredFormFields(cardType);
    }
  }, [isRequestAccess, cardType, requireAccessPass, visitorRegistrationFormValues]);

  useEffect(() => {
    if (isRequestAccess) {
      bamStore.loadBAMPrerequisites().then((pre) => {
        setForm_Prerequisites(pre);
      });
    }

    bamStore.loadBAMSpecialAccessAreas().then((saas) => {
      setLocationAccessAreas(bamStore.location_saas[locationId]);
    });

    bamStore.loadVMSAccessGroupAreas().then((saas) => {
      setVMSLocationAccessAreas(bamStore.vms_location_saas[locationId]);
    });
  }, []);

  return (
    <>
      <Box position="relative" p={mdAndUp ? 3 : 0} className={classes.form}>
        <Box py={2}>
          <Typography style={{ fontSize: 26 }} variant="h1">
            {isRequestAccess ? "Request Access to a BHP Location" : "Visitor Registration"}
          </Typography>
          {isRequestAccess ? (
            <Box mt={1} display={showRegistrationForm}>
              Use this form to register external visitors and/or request access to a corporate
              office by selecting the relevant card type. Effective building security is integral to
              BHP overall security strategy and is a fundamental risk-management measure to protect
              from unauthorised entry and consequential impacts to people, property and information.
              Our building operations and workplace have been adjusted with COVID-19 measures and
              controls to support our safety. Please check{" "}
              <Link
                href="https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Building%20Access%20and%20Access-Passes.aspx"
                target={"_blank"}
                // title="BHP - Request Access Information"
                style={{ textDecoration: "underline" }}
              >
                this page
              </Link>{" "}
              building-specific information on external visitor access requirements to visit our
              corporate offices. Please check{" "}
              <Link
                href="https://spo.bhpbilliton.com/sites/globalproperty?e=1%3A58046136b1b44923b567757c08f25439"
                target={"_blank"}
                title="BHP - Request Access Information"
                style={{ textDecoration: "underline" }}
              >
                this page
              </Link>{" "}
              for more information on BHP office locations.
            </Box>
          ) : (
            <Box mt={1} display={showRegistrationForm}>
              Please check{" "}
              <Link
                href="https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Visitor-access-information.aspx"
                target={"_blank"}
                title="BHP - visitor Access Information"
                style={{ textDecoration: "underline" }}
              >
                this
              </Link>{" "}
              page for building-specific information on external visitor access requirements to
              visit our corporate offices.
            </Box>
          )}
          <Box my={4} display={showRegistrationForm}>
            <Grid container alignItems="center">
              {formFieldTitle("Location*")}
              <Grid item xs={12} sm={8}>
                <SettingsSelectBox
                  t={t}
                  cssClass={classes.selectBox}
                  phText={"---"}
                  phValue={0}
                  theItems={buildingArray.slice().sort((a, b) => (a.city < b.city ? -1 : 1))}
                  labelField={"display_name"}
                  valueField={"id"}
                  handleChange={handleFieldChange}
                  defaultValue={visitorRegistrationFormValues["locationId"]}
                  theId={"location"}
                  theKey={"locationKey"}
                  name="locationId"
                  error={visitorRegistrationErrorValues["locationId"]}
                  helperText={visitorRegistrationHelperTextValues["locationId"]}
                />
              </Grid>
            </Grid>
            {isRequestAccess && (
              <Grid container alignItems="center">
                {formFieldTitle("Access Type*")}
                <Grid item xs={12} sm={8}>
                  <SettingsSelectBox
                    t={t}
                    cssClass={classes.selectBox}
                    phText={"Select Access Type"}
                    phValue={0}
                    theItems={cardTypesForLocation}
                    handleChange={handleFieldChange}
                    defaultValue={0}
                    valueField={"card_type"}
                    labelField={"name"}
                    theId={"cardType"}
                    theKey={"cardKey"}
                    name="cardTypeId"
                    error={visitorRegistrationErrorValues["cardTypeId"]}
                    helperText={visitorRegistrationHelperTextValues["cardTypeId"]}
                  />
                </Grid>
              </Grid>
            )}
            {isRequestAccess && ![CardType.None].includes(+cardType) && (
              <Grid container alignItems="center">
                {formFieldTitle(" ")}
                <Grid
                  item
                  xs={12}
                  sm={8}
                  style={{ alignItems: "center", color: bhpColor.blueGrey1 }}
                >
                  <Box
                    style={{
                      backgroundColor: bhpColor.blueGrey4,
                      padding: "12px",
                      width: smAndUp ? `calc(100% - 150px)` : `calc(100% - 10px)`,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          getCardTypeBlurb(cardType, visitorRegistrationFormValues["locationId"]) ||
                          "",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            {isRequestAccess &&
            showMainForm &&
            [
              CardType["Special Access Area"],
              CardType["Short-Term employee / Contractor"],
              CardType["Employee / Embedded Contractor"],
              CardType["Replacement Card"],
            ].includes(+cardType)
              ? drawNameAutoSelectBox(
                  "cardHolderId",
                  "Card Holder",
                  true,
                  cardHolderDropdownOptions,
                  handleCardHolderIdChange,
                  cardHolderFilterHandler
                )
              : ""}
            {isRequestAccess &&
              showMainForm &&
              [CardType["Special Access Area"]].includes(+cardType) &&
              drawNameAutoSelectBox(
                "supervisorId",
                "Supervisor",
                true,
                supervisorDropdownOptions,
                handleSupervisorIdChange,
                supervisorFilterHandler
              )}
            {showMainForm && (
              <Fragment>
                {!isRequestAccess && (
                  <Grid container alignItems="center">
                    {formFieldTitle("Access Pass?")}
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{ alignItems: "center", color: bhpColor.blueGrey1 }}
                    >
                      <Checkbox
                        style={{ paddingLeft: 0 }}
                        value={requireAccessPass}
                        onClick={(evt) => changeAccessType(evt)}
                        checked={accessPassCheckboxOn}
                        disabled={accessPassCheckboxEditable}
                      />
                      {smAndUp
                        ? "Check this box if the visitor will require a building access pass"
                        : "Building Access Pass Required"}
                    </Grid>
                  </Grid>
                )}

                {!isRequestAccess && requireAccessPass && (
                  <Grid container alignItems="center">
                    {formFieldTitle(" ")}
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{ alignItems: "center", color: bhpColor.blueGrey1 }}
                    >
                      <Box
                        style={{
                          backgroundColor: bhpColor.blueGrey4,
                          padding: "12px",
                          width: smAndUp ? `calc(100% - 10px)` : `calc(100%-150px)`,
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              getCardTypeBlurb(
                                CardType["External Visitor (pass required)"],
                                visitorRegistrationFormValues["locationId"]
                              ) || "",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {[
                  CardType.None,
                  CardType["External Visitor (pass required)"],
                  CardType["External Visitor (no pass required)"],
                  CardType["Child Visitor"],
                ].includes(+cardType) && visitors.map((idx) => drawVisitor(idx))}
                {[
                  CardType.None,
                  CardType["External Visitor (pass required)"],
                  CardType["External Visitor (no pass required)"],
                  CardType["Child Visitor"],
                ].includes(+cardType) && (
                  <Grid
                    container
                    style={{
                      marginTop: smAndUp ? "-80px" : "0",
                      marginBottom: smAndUp ? "80px" : "0",
                      height: "0px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginRight: smAndUp ? 0 : "10px",
                      }}
                    >
                      <Box
                        style={{
                          paddingLeft: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          fontSize: "14px",
                          color: bhpColor.blueGrey1,
                        }}
                      >
                        <Tooltip title="Include another visitor">
                          <Box
                            onClick={() => addVisitor()}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                          >
                            <PersonAddIcon />
                            Click to include another visitor
                          </Box>
                        </Tooltip>{" "}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {[CardType["Internal Visitor"]].includes(+cardType) &&
                  visitors.map((idx) => drawInternalVisitor(idx))}
                {[CardType["Internal Visitor"]].includes(+cardType) && (
                  <Grid
                    container
                    style={{
                      marginTop: smAndUp ? "0" : "0",
                      marginBottom: smAndUp ? "0" : "0",
                      height: "0px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginRight: smAndUp ? 0 : "10px",
                      }}
                    >
                      <Box
                        style={{
                          paddingLeft: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          fontSize: "14px",
                          color: bhpColor.blueGrey1,
                        }}
                      >
                        <Tooltip title="Include another visitor">
                          <Box
                            onClick={() => addVisitor()}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                          >
                            <PersonAddIcon />
                            Click to include another visitor
                          </Box>
                        </Tooltip>{" "}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {/* Add or remove multiple visitor logic for VMS gallagher for external visitors */}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) &&
                  visitors.map((idx) => drawVmsExternalVisitor(idx))}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) && (
                  <Grid
                    container
                    style={{
                      marginTop: smAndUp ? "0" : "0",
                      marginBottom: smAndUp ? "0" : "0",
                      height: "0px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        marginRight: smAndUp ? 0 : "10px",
                      }}
                    >
                      <Box
                        style={{
                          paddingLeft: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          fontSize: "14px",
                          color: bhpColor.blueGrey1,
                        }}
                      >
                        <Tooltip title="Include another visitor">
                          <Box
                            onClick={() => addVisitor()}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                          >
                            <PersonAddIcon />
                            Click to include another visitor
                          </Box>
                        </Tooltip>{" "}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) &&
                  visitorLimit && (
                    <Grid
                      container
                      style={{
                        marginTop: smAndUp ? "0" : "0",
                        marginBottom: smAndUp ? "0" : "0",
                        height: "4px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: smAndUp ? 0 : "10px",
                        }}
                      >
                        <Box
                          style={{
                            paddingLeft: "15px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                            fontSize: "14px",
                            color: bhpColor.orange1,
                          }}
                        >
                          More than 20 visitors can not be added in a single request. Please contact
                          Reception to discuss alternative arrangements.
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) && (
                  <Grid container alignItems="center">
                    {formFieldTitle(`Visit Type${getRequiredFlag("vmsAccessArea")}`)}
                    <Grid item xs={12} sm={8}>
                      <Select
                        disableUnderline
                        style={{
                          border: `2px solid ${bhpColor.grey4}`,
                          borderRadius: "4px",
                          paddingLeft: "12px",
                        }}
                        className={classes.selectBox}
                        id={"vmsAccessArea"}
                        // multiple
                        value={vmsAccessAreas}
                        input={<Input />}
                        onChange={(e) => handlevmsAccessAreaChange(e)}
                        MenuProps={MenuProps}
                        error={visitorRegistrationErrorValues["vmsAccessArea"]}
                      >
                        {vmsLocationAccessAreas &&
                          vmsLocationAccessAreas.map((name) => (
                            <MenuItem
                              key={name.id}
                              value={name.id}
                              style={getStyles(name, vmsAccessArea, theme)}
                            >
                              {name.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ color: "#f4433" }}>
                        {visitorRegistrationHelperTextValues["vmsAccessArea"]}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
                {(!isRequestAccess ||
                  [
                    CardType["Employee / Embedded Contractor"],
                    CardType["Short-Term employee / Contractor"],
                    CardType["Internal Visitor"],
                    CardType["External Visitor (pass required)"],
                    CardType["External Visitor (no pass required)"],
                    CardType["Child Visitor"],
                    CardType["External Visitor [pass required]"],
                  ].includes(+cardType)) &&
                  drawNameAutoSelectBox(
                    "hostNameId",
                    "Host Name",
                    true,
                    hostNameDropdownOptions,
                    handleHostNameIdChange,
                    hostNameFilterHandler
                  )}
                {(!isRequestAccess || ![CardType["Replacement Card"]].includes(+cardType)) && (
                  <Grid container alignItems="center">
                    {formFieldTitle("Start Date / Time*")}
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        flexBasis: smAndUp ? `calc(67% - 155px)` : `calc(100% - 10px)`,
                        maxWidth: "100%",
                        flexGrow: 0,
                      }}
                    >
                      <TextField
                        key="tFromDate"
                        id="fromDate"
                        placeholder={t("Enter a from date time (required)")}
                        type="datetime-local"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={handleFieldChange}
                        onBlur={copyAsEndDateIfEndEmpty}
                        value={visitorRegistrationFormValues["fromDateTime"]}
                        name="fromDateTime"
                        error={visitorRegistrationErrorValues["fromDateTime"]}
                        helperText={visitorRegistrationHelperTextValues["fromDateTime"]}
                      />
                    </Grid>
                  </Grid>
                )}
                {(!isRequestAccess || ![CardType["Replacement Card"]].includes(+cardType)) && (
                  <Grid container alignItems="center">
                    {formFieldTitle(`End Date / Time${isRequired("toDateTime") ? "*" : ""}`)}
                    <Grid
                      item
                      xs={8}
                      style={{
                        flexBasis: smAndUp ? `calc(67% - 155px)` : `calc(100% - 10px)`,
                        maxWidth: "100%",
                        flexGrow: 0,
                      }}
                    >
                      <TextField
                        key="tToDate"
                        id="toDate"
                        placeholder={t("Enter a to date time (required)")}
                        type="datetime-local"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={handleFieldChange}
                        value={visitorRegistrationFormValues["toDateTime"]}
                        name="toDateTime"
                        error={visitorRegistrationErrorValues["toDateTime"]}
                        helperText={visitorRegistrationHelperTextValues["toDateTime"]}
                        style={{ fontSize: ".9rem" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {(vmsStartDateValidation || vmsEndDateValidation) && (
                  <div style={{ color: "#e65400" }}>
                    <p>
                      This visit is scheduled before / after business hours, please arrange with
                      Reception to collect Access Cards in advance during business hours.
                    </p>
                  </div>
                )}
                {!isRequestAccess &&
                  !requireAccessPass &&
                  drawSimpleTextField(
                    `accessArea`,
                    `Access Area`,
                    isRequired(`accessArea`),
                    "Specify the area you need to access"
                  )}

                {isRequestAccess &&
                CardType.None !== cardType &&
                CardType["External Visitor [pass required]"] !== +cardType ? (
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginTop: "-10px", marginBottom: "-10px" }}
                  >
                    {formFieldTitle(`Access Hours${getRequiredFlag("accessHours")}`)}
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{ alignItems: "center", color: bhpColor.blueGrey1 }}
                    >
                      <Grid
                        container
                        style={{ marginLeft: 0, marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Grid item xs={5}>
                          <Radio
                            style={{ paddingLeft: 0 }}
                            value={1}
                            checked={pass24Hours}
                            onClick={() => change24HoursPass(true)}
                          />{" "}
                          Building Hours
                        </Grid>
                        <Grid item xs={7}>
                          <Radio
                            style={{ paddingLeft: 0 }}
                            value={2}
                            checked={!pass24Hours}
                            onClick={() => change24HoursPass(false)}
                          />{" "}
                          24 Hours
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                {[CardType["External Visitor (pass required)"]].includes(+cardType) &&
                  drawSimpleTextField(
                    `preferredFloorORMeetingRoomNumber`,
                    `Preferred floor/meeting room number`,
                    isRequired(`preferredFloorORMeetingRoomNumber`),
                    "Enter the preferred floor/meeting room number"
                  )}

                {![
                  CardType["Short-Term employee / Contractor"],
                  CardType["Employee / Embedded Contractor"],
                  CardType["Special Access Area"],
                  CardType["Replacement Card"],
                  CardType["External Visitor [pass required]"],
                ].includes(+cardType) && (
                  <Grid container>
                    <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
                      {formFieldTitle(`Reason for Visit${getRequiredFlag("reasonForVisit")}`)}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        key="tReason"
                        id="reason"
                        multiline
                        minRows={4}
                        placeholder={`${t("Start typing..")}${charLimit} ${t("character limit")}`}
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: charLimit }}
                        className={classes.textField}
                        onChange={handleFieldChange}
                        value={visitorRegistrationFormValues["reasonForVisit"] || ""}
                        error={visitorRegistrationErrorValues["reasonForVisit"]}
                        helperText={visitorRegistrationHelperTextValues["reasonForVisit"]}
                        name="reasonForVisit"
                      />
                      <Box
                        style={{
                          maxWidth: smAndUp ? "calc(100% - 150px)" : `calc(100% - 10px)`,
                          display: "flex",
                          justifyContent: "end",
                          fontSize: "smaller",
                          color: bhpColor.blueGrey3,
                        }}
                      >
                        {`${charLimit - visitorRegistrationFormValues["reasonForVisit"].length} ${t(
                          "left"
                        )}`}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {[CardType["Replacement Card"]].includes(+cardType) && (
                  <Grid container>
                    <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
                      {formFieldTitle(`Company`)}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        key={`tCompanyName`}
                        id={`fCompanyName`}
                        placeholder={`Company Name`}
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        onChange={handleFieldChange}
                        value={visitorRegistrationFormValues["companyName"]}
                        name={`companyName`}
                        error={visitorRegistrationErrorValues["companyName"]}
                        helperText={visitorRegistrationHelperTextValues["companyName"]}
                      />
                    </Grid>

                    <Grid
                      container
                      style={{ marginLeft: 0, marginTop: "10px", marginBottom: "10px" }}
                    >
                      <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
                        {formFieldTitle(
                          `Reason for replacement${getRequiredFlag("reasonForReplacement")}`
                        )}
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          key="tReasonForReplacement"
                          id="reasonForReplacement"
                          multiline
                          minRows={4}
                          placeholder={`${t("Start typing..")}${charLimit} ${t("character limit")}`}
                          type="text"
                          size="small"
                          variant="outlined"
                          fullWidth
                          inputProps={{ maxLength: charLimit }}
                          className={classes.textField}
                          onChange={handleFieldChange}
                          value={visitorRegistrationFormValues["reasonForReplacement"] || ""}
                          error={visitorRegistrationErrorValues["reasonForReplacement"]}
                          helperText={visitorRegistrationHelperTextValues["reasonForReplacement"]}
                          name="reasonForReplacement"
                        />
                        <Box
                          style={{
                            maxWidth: smAndUp ? "calc(100% - 150px)" : `calc(100% - 10px)`,
                            display: "flex",
                            justifyContent: "end",
                            fontSize: "smaller",
                            color: bhpColor.blueGrey3,
                          }}
                        >
                          {`${
                            charLimit - visitorRegistrationFormValues["reasonForReplacement"].length
                          } ${t("left")}`}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {isRequestAccess &&
                [
                  CardType["Child Visitor"],
                  CardType["Short-Term employee / Contractor"],
                  CardType["Employee / Embedded Contractor"],
                  CardType["Internal Visitor"],
                ].includes(+cardType) ? (
                  <Grid container>
                    <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
                      {formFieldTitle(`Company`)}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        key={`tCompanyName`}
                        id={`fCompanyName`}
                        placeholder={`Company Name`}
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        onChange={handleFieldChange}
                        value={visitorRegistrationFormValues["companyName"]}
                        name={`companyName`}
                        error={visitorRegistrationErrorValues["companyName"]}
                        helperText={visitorRegistrationHelperTextValues["companyName"]}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                {(requireAccessPass &&
                  CardType["External Visitor (no pass required)"] !== +cardType &&
                  CardType["Special Access Area"] !== +cardType &&
                  CardType["External Visitor [pass required]"] !== +cardType) ||
                (isRequestAccess &&
                  [
                    CardType["Internal Visitor"],
                    CardType["External Visitor (pass required)"],
                    CardType["Child Visitor"],
                    CardType["Short-Term employee / Contractor"],
                    CardType["Employee / Embedded Contractor"],
                  ].includes(+cardType)) ? (
                  <Grid container alignItems="center">
                    {formFieldTitle("Special Access")}
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        alignItems: "center",
                        color: bhpColor.blueGrey1,
                        fontStyle: "italic",
                      }}
                    >
                      <Checkbox
                        style={{ paddingLeft: 0 }}
                        value={specialAccessPass}
                        onClick={(evt) => changeSpecialAccessType(evt)}
                      />{" "}
                      Check this box if the special access is required
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {!isRequestAccess && !CardType["Special Access Area"] && drawAccessArea()}
                {((isRequestAccess && specialAccessPass) ||
                  (!isRequestAccess && requireAccessPass && specialAccessPass)) &&
                  drawAccessArea()}
                {((isRequestAccess && specialAccessPass) ||
                  (!isRequestAccess && requireAccessPass && specialAccessPass)) &&
                  drawAccessReason(true)}
                {isRequestAccess &&
                [
                  CardType["Short-Term employee / Contractor"],
                  CardType["Employee / Embedded Contractor"],
                ].includes(+cardType) ? (
                  <Grid container alignItems="center">
                    {formFieldTitle("Photo Upload")}
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      style={{
                        alignItems: "center",
                        color: bhpColor.blueGrey1,
                        fontStyle: "italic",
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <SaveAltIcon />
                          <input
                            id="photo"
                            type="file"
                            required
                            accept="image/png, image/jpeg"
                            onChange={handleImageChange}
                          />
                        </Grid>
                        {photo && sizeOk(photo) ? (
                          <Grid item xs={12}>
                            Name: {photo.name}, Size: {(photo.size / 1024 / 1024).toFixed(2)}Mb,
                            Type: {photo.type}
                          </Grid>
                        ) : (
                          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                            <Box>A photo is required for Reception to issue an Access Pass</Box>
                            {photo && !sizeOk(photo) && (
                              <Box style={{ fontSize: "12px", color: "#f44336" }}>
                                Image too large, must be less than {maxPhotoSize}Mb
                              </Box>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {[
                  CardType["Short-Term employee / Contractor"],
                  CardType["Employee / Embedded Contractor"],
                ].includes(+cardType) && (
                  <Grid container>
                    <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
                      {formFieldTitle(`Additional Information${getRequiredFlag("additionalInfo")}`)}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        key="tAdditionalInfo"
                        id="additionalInfo"
                        multiline
                        minRows={4}
                        placeholder={`${t("Start typing..")}${charLimit} ${t("character limit")}`}
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: charLimit }}
                        className={classes.textField}
                        onChange={handleFieldChange}
                        value={visitorRegistrationFormValues["additionalInfo"] || ""}
                        error={visitorRegistrationErrorValues["additionalInfo"]}
                        helperText={visitorRegistrationHelperTextValues["additionalInfo"]}
                        name="additionalInfo"
                      />
                      <Box
                        style={{
                          maxWidth: smAndUp ? "calc(100% - 150px)" : `calc(100% - 10px)`,
                          display: "flex",
                          justifyContent: "end",
                          fontSize: "smaller",
                          color: bhpColor.blueGrey3,
                        }}
                      >
                        {`${charLimit - visitorRegistrationFormValues["additionalInfo"].length} ${t(
                          "left"
                        )}`}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {/* VMS Gallagher form fields-meeting name and meeting description */}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) &&
                  drawSimpleTextField(
                    `meetingRoom`,
                    `Meeting Room`,
                    isRequired(`meetingRoom`),
                    "Enter the meeting room"
                  )}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) &&
                  drawSimpleTextField(
                    `meetingName`,
                    `Meeting Name`,
                    isRequired(`meetingName`),
                    "Enter the meeting name"
                  )}
                {[CardType["External Visitor [pass required]"]].includes(+cardType) && (
                  <Grid container>
                    <Grid item xs={3} style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}>
                      {formFieldTitle(
                        `Meeting Description${getRequiredFlag("meetingDescription")}`
                      )}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        key="tDescription"
                        id="descrition"
                        multiline
                        minRows={4}
                        placeholder={`${t("Start typing..")}${charLimit} ${t("character limit")}`}
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: charLimit }}
                        className={classes.textField}
                        onChange={handleFieldChange}
                        value={visitorRegistrationFormValues["meetingDescription"] || ""}
                        error={visitorRegistrationErrorValues["meetingDescription"]}
                        helperText={visitorRegistrationHelperTextValues["meetingDescription"]}
                        name="meetingDescription"
                      />
                      <Box
                        style={{
                          maxWidth: smAndUp ? "calc(100% - 150px)" : `calc(100% - 10px)`,
                          display: "flex",
                          justifyContent: "end",
                          fontSize: "smaller",
                          color: bhpColor.blueGrey3,
                        }}
                      >
                        {`${
                          charLimit - visitorRegistrationFormValues["meetingDescription"].length
                        } ${t("left")}`}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {/* Notify visitor and desk booking functionality */}
                {[
                  CardType["External Visitor (pass required)"],
                  CardType["Internal Visitor"],
                ].includes(+cardType) && (
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <table
                        style={{
                          width: smAndUp ? `calc(100% - 134px)` : `calc(100% - 10px)`,
                        }}
                      >
                        <tbody>
                          {isRequestAccess && (
                            <tr>
                              <td align="left">
                                <Checkbox
                                  name="bookADesk"
                                  color="primary"
                                  onChange={handleFieldChange}
                                />
                                {smAndUp
                                  ? "I would like to book a desk for my Visitor"
                                  : "Book Desk for Vistor"}
                                {visitors.length > 1 ? "s" : ""}
                              </td>
                            </tr>
                          )}
                          {isRequestAccess && (
                            <tr>
                              <td align="left">
                                <Checkbox
                                  name="notifyVisitor"
                                  color="primary"
                                  onChange={handleFieldChange}
                                />
                                Notify Visitor of registration
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                )}
                {/* Notify visitor functionality */}
                {[
                  CardType["External Visitor (no pass required)"],
                  CardType["External Visitor [pass required]"],
                ].includes(+cardType) && (
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <table
                        style={{
                          width: smAndUp ? `calc(100% - 134px)` : `calc(100% - 10px)`,
                        }}
                      >
                        <tbody>
                          {isRequestAccess && (
                            <tr>
                              <td align="left">
                                <Checkbox
                                  name="notifyVisitor"
                                  color="primary"
                                  onChange={handleFieldChange}
                                />
                                Notify Visitor of registration
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                )}
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <table
                      style={{
                        width: smAndUp ? `calc(100% - 134px)` : `calc(100% - 10px)`,
                      }}
                    >
                      <tbody>
                        {isRequestAccess && (
                          <tr>
                            <td align="left">
                              <Button
                                style={{ marginTop: 8, marginRight: 8 }}
                                onClick={doVisitorRegistrationBooking}
                                variant="contained"
                                disableElevation
                                color="primary"
                                disabled={registrationButtonStatus}
                              >
                                {t("Submit")}
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={5000}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (!isApiError) {
            history.push("/temp");
            history.goBack();
          } else {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
      <Box
        className={teamClasses.root}
        py={1}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        display={showExternalRegistrationLink}
      >
        <Paper className={teamClasses.paper} elevation={0}>
          <div style={{ height: "100px", lineHeight: "35px", textAlign: "center" }}>
            This location is not registering visitors. Please refer to your Property & Workplace
            Site Lead for more information about visitor management at this location. Alternatively,
            in the absence of a Site Lead, you can contact your{" "}
            <a href={externalLink} target="_blank">
              Admin
            </a>
            <p>
              <Button
                style={{ marginTop: 8, marginRight: 8 }}
                onClick={() => {
                  history.push("/temp");
                  history.goBack();
                }}
                variant="contained"
                disableElevation
                color="primary"
              >
                Back
              </Button>
            </p>
          </div>
        </Paper>
      </Box>
      <Dialog open={photoInformationOpen}>
        <DialogTitle className={classes.dialogTitle}>{t("Photo Requirements")}</DialogTitle>
        <DialogContent>
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: `<img src=${PhotoSpecImage} style="float:right; margin-left:12px;" />Acceptable photos can be in colour or black and white and can be taken by anyone but must be:<ul><li>clear, sharp and in focus</li><li>taken with a neutral facial expression, that is, eyes open and clearly visible, mouth closed</li><li>taken with uniform lighting, without shadows, glare or flash reflections</li>taken straight on, with face and shoulders centered and squared to the camera</li><li>taken in front of a plain white or light-coloured background, with a clear difference between the face and the background</li><li>        must represent natural skin tones</li><li>        must be originals and not taken from an existing photo</li><li>        taken within no more than 12 months before the date you submit the application and reflect applicant's current appearance</li></ul><strong>Attention</strong><ul><li>prescription eyeglasses may be worn as long as the eyes are clearly visible and there is no reflection or glare on the eyeglasses</li><li>sunglasses and tinted eyeglasses are unacceptable</li><li>photos with the red-eye effect or red-eye alterations are unacceptable</li><li>head coverings are not acceptable, unless worn for religious beliefs or medical reasons:</li><li>the full face must be clearly visible and the head covering must not cast any shadows on the face</li><li>hair can be worn down</li></ul>`,
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePhotoInformationClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <LoadingZone isLoading={isSending} />
    </>
  );
});

export default VisitiorRegistration;
